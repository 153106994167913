import {
  Maybe,
  SpendBankTransaction,
  SpendMemoInvoice,
  SpendSortInput,
  useSpendGroupByIdLazyQuery,
} from "graphql/generated";
import useModal from "hooks/use-modal";
import { useContext, useEffect, useState } from "react";
import TransactionDetailsModal from "shared-components/modal/transaction-details.tsx";
import { SnapPagination } from "suit";
import Cards from "./card";
import Table from "./table";
import { ITEMS_PER_PAGE } from "../../../constants";
import DisplayContext from "context/display-context";
import InvoiceDetails from "shared-components/modal/invoice-details";
import { TRLVItems } from "../invoices/invoice-list-item";
import { TableValueStyle } from "shared-components/table-row-label-value";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import GroupContext from "context/group-context";
import { useNavigate } from "react-router-dom";
import SeasonContext from "context/season-context";

type TransactionListItemProps = {
  listItems: SpendBankTransaction[] | undefined;
  totalTransactions: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  isNotesReadOnly: boolean;
  loadingTransactions?: boolean;
  exporting?: boolean;
  toggleSort: (field: string) => void;
  currentSort: SpendSortInput | undefined;
};

function TransactionListItem({
  listItems,
  totalTransactions,
  page,
  setPage,
  isNotesReadOnly,
  loadingTransactions,
  exporting,
  toggleSort,
  currentSort,
}: TransactionListItemProps) {
  const display = useContext(DisplayContext);
  const group = useContext(GroupContext);
  const season = useContext(SeasonContext);
  const navigate = useNavigate();
  const { toggle, isOpen, tabSelectedValue, setSelectedTab } = useModal();
  const { toggle: invoiceDetailsToggle, isOpen: isInvoiceDetailsOpen } =
    useModal();
  const [selectedTran, setSelectedTran] = useState<
    SpendBankTransaction | any | undefined
  >(undefined);
  const [selectedModal, setSelectedModal] = useState(0);
  const [modalData, setModalData] = useState<TRLVItems[]>([]);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState("");

  const [getGroup, { data: groupData, loading: loadingGroup }] =
    useSpendGroupByIdLazyQuery();

  useEffect(() => {
    if (!loadingGroup && groupData) {
      const selectedGroup = groupData.spendGroupById;
      group?.setAndStoreActiveGroup({
        ...group!,
      });
      season?.handleSelectedSeason({ seasonsToSet: selectedGroup?.seasons });
      group?.navigateGroupPage("collections", selectedGroup?.id ?? "");
    }
    // eslint-disable-next-line
  }, [loadingGroup, groupData]);

  const handleGroupLink = (groupId: string, seasonId: string) => {
    getGroup({
      variables: {
        spendGroupByIdId: groupId,
      },
    });
    season?.handleSelectedSeason({ seasonId });
  };

  const setModalDataAndShow = (transaction: SpendBankTransaction) => {
    setSelectedModal(0);
    setSelectedTran(transaction);
    toggle();
  };

  const handleNavigateParticipant = (userId: string) => {
    navigate(`/people/participants/participant-details/${userId}`);
  };

  const handleToggleInvoiceModal = (
    item: Maybe<SpendMemoInvoice> | undefined,
    creditApplied: number
  ) => {
    let items: TRLVItems[] = [];
    setSelectedInvoiceId(item?.id ?? "");
    items.push({
      label: "Description",
      value: item?.description ?? "",
    });
    items.push({
      label: "Participant",
      value: item?.participantName ?? "",
      valueStyle: TableValueStyle.LINK,
      onClick: () => {
        handleNavigateParticipant(item?.rosterId ?? "");
      },
    });
    items.push({
      label: "Group",
      value: item?.groupName ?? "",
      valueStyle: TableValueStyle.LINK,
      onClick: () => {
        handleGroupLink(item?.groupId ?? "", item?.seasonId ?? "");
      },
    });
    items.push({
      label: "Status",
      value: item?.status?.replace("_", " ") ?? "",
      valueStyle: TableValueStyle.BADGE,
    });
    items.push({
      label: "Due Date",
      value: FormatDate(item?.dueDate ?? "", DateFormatSupported.Numbers),
    });
    items.push({
      label: "Season",
      value: item?.seasonName ?? "",
    });
    items.push({
      label: "Invoice Amount",
      value: FormatMoney(item?.amount ?? 0),
    });
    items.push({
      label: "Balance Due",
      value: FormatMoney(item?.balanceDue ?? 0),
    });
    const discountAndCredits = (item?.discountAmount ?? 0) + creditApplied;
    if (discountAndCredits > 0) {
      items.push({
        label: "Credits & Discounts",
        value: FormatMoney(discountAndCredits),
      });
    }
    setModalData(items);
    invoiceDetailsToggle();
  };

  return (
    <>
      {display?.isDesktop ? (
        <Table
          listItems={listItems}
          setModalDataAndShow={setModalDataAndShow}
          loadingTransactions={loadingTransactions}
          exporting={exporting}
          handleToggleInvoiceModal={handleToggleInvoiceModal}
          toggleSort={toggleSort}
          currentSort={currentSort}
        />
      ) : (
        <Cards
          listItems={listItems}
          setModalDataAndShow={setModalDataAndShow}
          loadingTransactions={loadingTransactions}
          exporting={exporting}
          handleToggleInvoiceModal={handleToggleInvoiceModal}
        />
      )}

      <SnapPagination
        currentPage={page}
        itemCount={totalTransactions}
        pageSize={ITEMS_PER_PAGE}
        onSnap-pagination-page-changed={(e) => {
          setPage(e.detail);
        }}
      />
      {selectedTran && isOpen && (
        <TransactionDetailsModal
          isOpen={isOpen}
          toggle={toggle}
          tabSelectedValue={tabSelectedValue}
          selectedTran={selectedTran}
          setSelectedModal={setSelectedModal}
          setSelectedTab={setSelectedTab}
          selectedModal={selectedModal}
          canEditNotesAndAttachments={isNotesReadOnly}
        />
      )}
      <InvoiceDetails
        isOpen={isInvoiceDetailsOpen}
        toggle={invoiceDetailsToggle}
        modalData={modalData}
        setModalData={setModalData}
        invoiceId={selectedInvoiceId}
      />
    </>
  );
}

export default TransactionListItem;
