import { useMutation, useQuery } from "@apollo/client";
import UserContext from "context/user-context";
import {
  InvoiceFilterEnum,
  PaymentsApiCustomerPaymentMethod,
  SpendBankAccount,
  SpendGroup,
  SpendInvoice,
  SpendSettings,
  usePaymentsApiCustomerMutation,
  useSpendInvoiceByIdLazyQuery,
  useSpendInvoicesQuery,
  useSpendPaymentMethodCreateMutation,
  useSpendSignupGroupIdQuery,
  useSpendUserRoleSetMutation,
  useSpendUserSignupUpdateMutation,
} from "graphql/generated";
import { UPDATE_USER_INVITE_STATUS } from "graphql/mutations/invite";
import { GET_SETTINGS } from "graphql/queries/settings";
import { useContextStrict } from "helpers/context-strict";
import { FormatMoney } from "helpers/format-money";
import { calcDiscount } from "helpers/invoices";
import { MapAndCalcSum } from "helpers/map-and-reduce";
import useModal from "hooks/use-modal";
import useToast from "hooks/use-toast";
import React, { useEffect, useState } from "react";
import { calcTotalPlusFee } from "shared-components/modal/make-payment/make-payment-helper";
import SessionTimeout from "shared-components/modal/session-timeout";
import ToastMessage from "shared-components/toast-message";
import { SnapButton, SnapLink } from "suit";
import ReviewInvoices from "./review-invoices";
import { PaymentTimingValue } from "../../types/invoice";
import Spinner from "../../shared-components/spinner";
import NewAuthorizePayments from "./new-authorize-payments";
import { useNavigate } from "react-router-dom";

type DisplayFlowProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

function DisplayInviteFlow({ setIsLoading }: DisplayFlowProps) {
  const navigate = useNavigate();
  const User = useContextStrict(UserContext);

  const { isOpen: isSessionTimeoutOpen, toggle: toggleSessionTimeout } =
    useModal();
  const { isToastOpen, toggleToast, ...toast } = useToast();
  const [changeRole] = useSpendUserRoleSetMutation();

  const { loading: loadingGroup, data: groupData } = useSpendSignupGroupIdQuery(
    {
      variables: {
        spendGroupByIdId: User._session?.role?.groupId ?? "",
      },
    }
  );

  const { loading: loadingInvoices, data: invoiceData } = useSpendInvoicesQuery(
    {
      variables: {
        filterBy: InvoiceFilterEnum.InviteId,
        filterValue: User._session?.inviteId,
        parentSignUp: true,
      },
    }
  );
  const { loading: loadingSettings, data: settingsData } =
    useQuery(GET_SETTINGS);

  const [
    getPaymentMethods,
    { loading: loadingPaymentMethod, data: paymentMethodData },
  ] = usePaymentsApiCustomerMutation();

  const loading =
    loadingGroup || loadingInvoices || loadingSettings || loadingPaymentMethod;

  const [updateUserStatus] = useSpendUserSignupUpdateMutation({
    onCompleted: ({ spendUserSignupUpdate }) => {
      console.log("completed user status update", spendUserSignupUpdate);

      setTimeout(() => {
        // @ts-ignore
        window.location.reload(false);
      }, 1000);
    },
    onError: (error) => {
      setSubmitting(false);
    },
  });

  const [createPaymentMethod] = useSpendPaymentMethodCreateMutation({
    onCompleted: ({ spendPaymentMethodCreate }) => {
      console.log(
        "completed payment method creation",
        spendPaymentMethodCreate
      );
      if (groupData?.spendGroupById?.id && User._session?.role?.seasonId) {
        updateUserStatus({
          variables: {
            input: {
              status: "completed",
              groupId: groupData?.spendGroupById?.id,
              seasonId: User._session.role.seasonId,
              inviteId: User._session.inviteId,
            },
          },
          refetchQueries: [],
        });
      } else {
        toast.setToastProps({
          message:
            "Payment Processed, but there was an issue with your invite. Please contact support to assist.",
          type: "danger",
        });
        toggleToast();
      }
    },
    onError: (error) => {
      const message = error.message || "(UNW)";
      console.log(message);
      toast.setToastProps({
        message: `Unable to process your payment: Please contact support to assist.`,
        type: "danger",
      });
      toggleToast();
      setTriedToProcessPayment(false);
      setSubmitting(false);
    },
  });

  const [
    updateUserInviteStatus,
    { loading: loadingInviteUpdate, data: updateInviteStatus },
  ] = useMutation(UPDATE_USER_INVITE_STATUS);

  const [getInvoiceById] = useSpendInvoiceByIdLazyQuery();

  const [step, setStep] = useState(0);
  const [group, setGroup] = useState<SpendGroup>({});
  const [invoices, setInvoices] = useState<SpendInvoice[]>([]);
  const [playerName, setPlayerName] = useState("");
  const [settings, setSettings] = useState<SpendSettings>({});
  const [paymentTiming, setPaymentTiming] = useState<
    PaymentTimingValue | undefined
  >(undefined);
  const [selectedPaymentTypeOption, setSelectedPaymentTypeOption] = useState<
    `Pay by: ${"Card" | "Bank"}` | undefined
  >();
  const [selectedBankAccount, setSelectedBankAccount] = useState<
    SpendBankAccount | undefined
  >();
  const [btnText, setBtnText] = useState("Next");
  const [total, setTotal] = useState(0);
  const [invoicesToPay, setInvoicesToPay] = useState<SpendInvoice[]>([]);
  const [accountId, setAccountId] = useState("");
  const [userAgreed, setUserAgreed] = useState(false);
  const [authorizedAt, setIsAuthorizedAt] = useState<string | undefined>();
  const [optInList, setOptInList] = useState<string[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<
    PaymentsApiCustomerPaymentMethod[]
  >([]);
  const [email, setEmail] = useState("");
  const [widgetOpen, setWidgetOpen] = useState(false);
  const [triedToProcessPayment, setTriedToProcessPayment] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [cancelInvite, setCancelInvite] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [seasonName, setSeasonName] = useState("");
  const [hasCreditsApplied, setHasCreditsApplied] = useState(false);
  let modalPromptTimer: NodeJS.Timeout | null = null;
  useEffect(() => {
    if (!loadingInviteUpdate && updateInviteStatus) {
      navigate("/dashboard");
      window.location.reload();
    }
    // eslint-disable-next-line
  }, [loadingInviteUpdate, updateInviteStatus]);

  useEffect(() => {
    setIsAuthorizedAt(isAuthorized ? new Date().toISOString() : undefined);
    // eslint-disable-next-line
  }, [isAuthorized]);

  useEffect(() => {
    if (paymentMethodData && paymentMethodData.paymentsApiCustomer) {
      setWidgetOpen(
        paymentMethodData.paymentsApiCustomer.paymentMethods.length === 0
      );
      setPaymentMethods(
        paymentMethodData.paymentsApiCustomer
          .paymentMethods as PaymentsApiCustomerPaymentMethod[]
      );
    }
  }, [loadingPaymentMethod, paymentMethodData]);

  useEffect(() => {
    if (
      !loadingInvoices &&
      !loadingGroup &&
      invoiceData &&
      invoiceData.spendInvoices &&
      groupData &&
      groupData.spendGroupById
    ) {
      let invoice = invoiceData.spendInvoices.invoices as SpendInvoice[];
      let hasMemo = invoice.some((inv) => inv.creditMemos?.length);

      setHasCreditsApplied(hasMemo);
      const email = User.getEmail();

      setInvoices(invoice);
      setEmail(email);
      const targetedInvite = User._session?.pendingInvites?.find(
        (pi) => pi.id === User?._session?.inviteId
      );
      const inviteCount = User._session?.pendingInvites?.length ?? 0;
      if (inviteCount > 1) {
        setCancelInvite(User._session?.inviteId ?? "");
      }
      if (!!targetedInvite) {
        setPlayerName(targetedInvite.rosterName ?? "n/a");
        getPaymentMethods();
      } else if (User.getInvite() == null) {
        toast.setToastProps({
          message: "Unable to find your participant's account.",
          type: "danger",
        });
        toggleToast();
      }

      setGroup(groupData.spendGroupById);
      setSeasonName(User.getInvite()?.seasonName ?? "");
      setAccountId(groupData.spendGroupById.accountId!);
    }
    if (settingsData && settingsData.spendSettings) {
      setSettings(settingsData.spendSettings);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [
    loadingGroup,
    groupData,
    loadingInvoices,
    invoiceData,
    loadingSettings,
    settingsData,
    setIsLoading,
  ]);

  useEffect(() => {
    if (User._session) {
      if (User._session.status === "alternate_signup") {
        if (User._session.inviteId == null) {
          toast.setToastProps({
            message: "Invite id not provided with alternate signup",
            type: "danger",
          });
          toggleToast();
        } else {
          setCancelInvite(User._session.inviteId ?? "");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [User._session]);

  useEffect(() => {
    let tempInvoices =
      invoices &&
      invoices.filter(
        (invoices) =>
          !invoices.isOptional ||
          invoices.optedIn ||
          optInList.includes(invoices.id ?? "")
      );
    let updatedInvoices = tempInvoices.map((invoice) => {
      return {
        ...invoice,
        balanceDue:
          selectedPaymentTypeOption === "Pay by: Bank"
            ? calcTotalPlusFee(
                invoice.balanceDue ?? 0,
                group?.organizationFees?.achPercent ?? 0,
                group?.organizationFees?.achBaseFee ?? 0
              )
            : calcTotalPlusFee(
                invoice.balanceDue ?? 0,
                group?.organizationFees?.cardPercent ?? 0,
                group?.organizationFees?.cardBaseFee ?? 0
              ),
      };
    });
    let tempTotal = 0;
    updatedInvoices.forEach((invoice) => {
      tempTotal += invoice.balanceDue ?? 0;
    });
    setInvoicesToPay(updatedInvoices);
    setTotal(tempTotal);
    // eslint-disable-next-line
  }, [selectedPaymentTypeOption, optInList.join(",")]);

  useEffect(() => {
    const amount = MapAndCalcSum(
      invoices.filter(
        (inv) => inv.isOptional === false || optInList.includes(inv.id!)
      ),
      "balanceDue"
    );
    if (
      group.minimumDiscountPurchase == null ||
      group.discountAmount == null ||
      group.discountCutOffDate == null ||
      group.enableDiscount !== true ||
      !(
        amount >= group.minimumDiscountPurchase &&
        new Date(group.discountCutOffDate).getTime() >= new Date().getTime()
      )
    ) {
      setShowDiscount(false);
    } else {
      setShowDiscount(true);
    }
  }, [invoices, group, optInList]);

  useEffect(() => {
    restartAutoReset();

    window.addEventListener("mousemove", restartAutoReset);

    return () => {
      if (modalPromptTimer) {
        clearTimeout(modalPromptTimer);
        window.removeEventListener("mousemove", restartAutoReset);
      }
    };
    // eslint-disable-next-line
  }, [location.pathname]);

  const restartAutoReset = () => {
    if (modalPromptTimer) {
      clearTimeout(modalPromptTimer);
    }
    modalPromptTimer = setTimeout(() => {
      toggleSessionTimeout();
    }, 60000 * 15);
  };

  const paymentMethodCreate = () => {
    setWidgetOpen(false);
    getPaymentMethods();
  };

  const handlePrev = () => {
    if (step !== 0) {
      setStep(step - 1);
    }
    if (showDiscount && paymentTiming === "All" && step === 1) {
      const updatedInvoicesToPay = invoicesToPay.map((inv) => ({
        ...inv,
        balanceDue: inv.balanceDue! + (inv.discountAmount || 0),
        discountAmount: 0,
      }));
      setTotal(total + (group.discountAmount || 0));
      setInvoicesToPay(updatedInvoicesToPay);
    }
  };
  const handleNext = async () => {
    const alreadyPaid = await handleValidateInvoices();
    if (alreadyPaid) {
      toast.setToastProps({
        message:
          "One or more of the selected invoices have already been paid. Please refresh to re-sync",
        type: "danger",
      });
      toggleToast();
    } else if (!selectedPaymentTypeOption) {
      toast.setToastProps({
        message: "Please Select a Payment Method",
        type: "danger",
      });
      toggleToast();
    } else if (
      !selectedBankAccount &&
      selectedPaymentTypeOption === "Pay by: Bank"
    ) {
      toast.setToastProps({
        message: "Please Select an Account",
        type: "danger",
      });
      toggleToast();
    } else if (
      !paymentMethods.length &&
      selectedPaymentTypeOption === "Pay by: Card"
    ) {
      toast.setToastProps({
        message: "Please Select a Card",
        type: "danger",
      });
      toggleToast();
    } else if (!paymentTiming) {
      toast.setToastProps({
        message: "Please select a Payment Timing",
        type: "danger",
      });
      toggleToast();
    } else {
      if (step + 1 <= 1) {
        setStep(step + 1);
      }
      if (step + 1 === 1) {
        let totalOverride = total;
        if (showDiscount && paymentTiming === "All") {
          const discounts = group.discountAmount
            ? calcDiscount(group.discountAmount, invoicesToPay)
            : [];
          const invoicesToPayUpdate = invoicesToPay.map((inv) => {
            const discountValue =
              discounts.find((id) => id.invoiceId === inv.id)?.discountAmount ||
              0;
            return {
              ...inv,
              balanceDue: (inv.balanceDue ?? 0) - discountValue,
              discountAmount: discountValue,
            };
          });
          totalOverride = total - (group.discountAmount || 0);
          setTotal(totalOverride);
          setInvoicesToPay(invoicesToPayUpdate);
        }
        setBtnText(
          paymentTiming === "All"
            ? `Pay ${FormatMoney(totalOverride)} Now`
            : paymentTiming === "AutoPay"
            ? "Authorize AutoPay"
            : "Confirm"
        );
      } else if (step === 1) {
        if (!isAuthorized) {
          //THESE ARE FORMAT CHANGES
          toast.setToastProps({
            message: "Please authorize payment",
            type: "danger",
          });
          toggleToast();
        } else if (group.isRequireAgreement && !userAgreed) {
          toast.setToastProps({
            message: "Please review and accept the agreement",
            type: "danger",
          });
          toggleToast();
        } else {
          submitPaymentMethod();
          //END OF FORMAT CHANGES
        }
      }
    }
  };

  const submitPaymentMethod = async () => {
    setSubmitting(true);

    const paymentMethodSource =
      selectedPaymentTypeOption === "Pay by: Bank" ? "ACH" : "CARD";

    // TODO: handle payment method card id;
    let paymentMethodId = paymentMethods?.at(0)?.id ?? "";

    if (!triedToProcessPayment) {
      setTriedToProcessPayment(true);
      const invoiceIds = invoicesToPay.reduce((acc, invoice) => {
        if (invoice.id) {
          acc.push(invoice.id);
        }
        return acc;
      }, [] as string[]);
      await createPaymentMethod({
        variables: {
          input: {
            amount: total,
            isAutoPayAuthorized: paymentTiming === "AutoPay",
            paymentMethodSource: paymentMethodSource,
            paymentMethodId: paymentMethodId ?? "",
            paymentMethodTiming: paymentTiming,
            invoiceIds,
            hasApprovedAgreement: userAgreed,
            authorizedAt: authorizedAt,
            discountAmounts: showDiscount
              ? calcDiscount(group.discountAmount, invoicesToPay)
              : undefined,
            inviteId: User._session?.inviteId,
          },
        },
      });
    } else {
      toast.setToastProps({
        message: `Page information is no longer in sync. Please refresh.`,
        type: "danger",
      });
      toggleToast();
    }
  };

  const handleUpdateInvoice = (updatedInvoice: SpendInvoice, idx: number) => {
    let tempInvoices = [...invoices];
    tempInvoices.splice(idx, 1, updatedInvoice);
    setInvoices(tempInvoices);
  };

  const handleCancelInvite = () => {
    updateUserInviteStatus({
      variables: {
        spendUserInviteStatusUpdateId: cancelInvite,
        status: "canceled",
      },
    }).then(() => {
      const role = User?.getAllRoles()?.find(
        (role) => role.isNotSignedUp === false
      );
      if (role) {
        changeRole({
          variables: {
            roleId: role.id,
          },
        });
      }
    });
  };

  const handleValidateInvoices = async () => {
    const selectedInvoices = invoices.filter((i) => i.optedIn);
    let paid = false;
    for (const i of selectedInvoices) {
      if (i.id) {
        const resp = await getInvoiceById({
          variables: {
            id: i.id,
          },
        });
        if (resp.data?.spendInvoiceById?.paid) {
          paid = true;
        }
      }
    }
    return paid;
  };

  return (
    <div className={`bg-gray-100 pt-4 lg:pb-4 pb-6 px-6 h-full`}>
      {isToastOpen && (
        <ToastMessage
          message={toast.message}
          isToastOpen={isToastOpen}
          toggleToast={toggleToast}
          type={toast.type}
        />
      )}

      <div
        className={`flex flex-col bg-white border border-gray-300 rounded-lg h-full pt-6 lg:pt-16 px-4 mb-14`}
      >
        <div className="flex self-end">
          <SnapLink
            href="https://helpdesk.snapraise.com/support-center/guardian-quick-start-guide-spend"
            target="_blank"
          >
            Review our support center article for assistance
          </SnapLink>
        </div>
        {
          {
            0: (
              <ReviewInvoices
                paymentTiming={paymentTiming}
                setPaymentTiming={setPaymentTiming}
                group={group}
                invoices={invoices}
                invoicesLoading={loadingInvoices}
                playerName={playerName}
                handleUpdateInvoice={handleUpdateInvoice}
                selectedPaymentTypeOption={selectedPaymentTypeOption}
                setSelectedPaymentTypeOption={setSelectedPaymentTypeOption}
                setSelectedBankAccount={setSelectedBankAccount}
                accountId={accountId}
                optInList={optInList}
                setOptInList={setOptInList}
                paymentMethods={paymentMethods}
                email={email}
                getPaymentMethods={getPaymentMethods}
                paymentMethodCreate={paymentMethodCreate}
                widgetOpen={widgetOpen}
                dotState={0}
                dotMarker={2}
                showDiscount={showDiscount}
                seasonName={seasonName}
                hasCreditsApplied={hasCreditsApplied}
              />
            ),
            1: (
              <NewAuthorizePayments
                settings={settings}
                group={group}
                invoices={invoices}
                playerName={playerName}
                paymentTiming={paymentTiming}
                selectedPaymentTypeOption={selectedPaymentTypeOption}
                total={total}
                setTotal={setTotal}
                agreed={userAgreed}
                setAgreed={setUserAgreed}
                bankAccount={selectedBankAccount}
                invoicesToPay={invoicesToPay}
                paymentMethods={paymentMethods}
                isAuthorized={isAuthorized}
                setIsAuthorized={setIsAuthorized}
                dotState={1}
                dotMarker={2}
              />
            ),
          }[step]
        }
        <div
          className={`flex justify-center mt-auto pt-4 mb-6 lg:justify-end lg:mt-4 mx-4 lg:mx-0`}
        >
          {step === 0 && cancelInvite !== "" && (
            <SnapButton
              variant="danger"
              className="mr-auto w-full lg:w-48"
              fullWidth
              onClick={handleCancelInvite}
            >
              Cancel Invite
            </SnapButton>
          )}
          {step === 1 && (
            <SnapButton
              icon="arrow-left-line"
              variant="tertiary"
              className="mr-4 w-full lg:w-48"
              fullWidth
              disabled={submitting}
              onClick={handlePrev}
            >
              Back
            </SnapButton>
          )}
          {step !== 1 ? (
            <SnapButton
              icon="arrow-right-line"
              iconPosition="right"
              variant="primary"
              className="w-full lg:w-48"
              fullWidth
              disabled={loading}
              onClick={handleNext}
            >
              Next
            </SnapButton>
          ) : (
            <div className="relative">
              {submitting && (
                <Spinner
                  size={"small"}
                  className={"absolute right-2 top-1/2 -translate-y-1/2"}
                />
              )}
              <SnapButton
                variant="primary"
                className="w-full lg:w-48"
                fullWidth
                onClick={handleNext}
                disabled={submitting || !isAuthorized}
              >
                {btnText}
              </SnapButton>
            </div>
          )}
        </div>
      </div>
      {isSessionTimeoutOpen && (
        <SessionTimeout
          isSessionTimeoutOpen={isSessionTimeoutOpen}
          toggleSessionTimeout={toggleSessionTimeout}
        />
      )}
    </div>
  );
}

export default DisplayInviteFlow;
