import CustomModal from "shared-components/modal";
import TableRowLabelValue from "shared-components/table-row-label-value";
import { TRLVItems } from "../../pages/reporting/invoices/invoice-list-item";
import { useSpendInvoiceByIdLazyQuery } from "graphql/generated";
import { useEffect } from "react";
import { FormatDate } from "helpers/format-date";

type InvoiceDetailsProps = {
  isOpen: boolean;
  toggle: () => void;
  modalData: TRLVItems[];
  setModalData: React.Dispatch<React.SetStateAction<TRLVItems[]>>;
  invoiceId: string;
};

function InvoiceDetails({
  isOpen,
  toggle,
  modalData,
  setModalData,
  invoiceId,
}: InvoiceDetailsProps) {
  const [getInvoiceById, { loading: loadingInvoiceData, data: invoiceData }] =
    useSpendInvoiceByIdLazyQuery();

  useEffect(() => {
    getInvoiceById({
      variables: {
        id: invoiceId,
      },
    });
  }, [invoiceId, getInvoiceById]);

  useEffect(() => {
    if (!loadingInvoiceData && invoiceData) {
      let items: TRLVItems[] = [...modalData];
      if (invoiceData.spendInvoiceById?.paidDate) {
        items.push({
          label: "Paid Date",
          value: FormatDate(invoiceData.spendInvoiceById?.paidDate),
        });
      }
      if (invoiceData.spendInvoiceById?.transactionStatus) {
        items.push({
          label: "Transaction Status",
          value: invoiceData.spendInvoiceById?.transactionStatus ?? "",
        });
      }
      if (invoiceData.spendInvoiceById?.transactionSettledDate) {
        items.push({
          label: "Transaction Settle Date",
          value: invoiceData.spendInvoiceById?.transactionSettledDate ?? "",
        });
      }
      if (invoiceData.spendInvoiceById?.transactionRejectReason) {
        items.push({
          label: "Transaction Rejection Reason",
          value: invoiceData.spendInvoiceById?.transactionRejectReason ?? "",
        });
      }
      setModalData(items);
    }
    // eslint-disable-next-line
  }, [loadingInvoiceData, invoiceData]);

  return (
    <CustomModal isOpen={isOpen} toggle={toggle} title="Invoice Details">
      <div className="modal-card">
        <table className="w-full">
          <tbody>
            {modalData.map((data: TRLVItems, idx: number) => {
              return (
                <TableRowLabelValue
                  key={idx}
                  label={data.label}
                  value={data.value}
                  className={data.className}
                  valueStyle={data.valueStyle}
                  onClick={data.onClick}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </CustomModal>
  );
}

export default InvoiceDetails;
