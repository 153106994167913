import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import { Maybe, SpendGroupRoster, SpendInvoice } from "graphql/generated";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { getBadgeColor } from "helpers/status-color";
import useModal from "hooks/use-modal";
import { TRLVItems } from "pages/reporting/invoices/invoice-list-item";
import { useEffect, useState } from "react";
import DataCard from "shared-components/data-card";
import Divider from "shared-components/divider";
import InvoiceDetails from "shared-components/modal/invoice-details";
import ShowingResults from "shared-components/showing-results";
import { TableValueStyle } from "shared-components/table-row-label-value";
import { SnapBadge, SnapDropDown, SnapIcon, SnapPagination } from "suit";
import { LabelValueObject } from "types/label-value-object";
import EditDueDate from "./modals/edit-due-date";
import EditInvoiceAmount from "./modals/edit-invoice-amount";
import RefundPayment from "./modals/refund-payment";
import EditCreditMemo from "./modals/edit-credit-memo";
import { SnapPaginationCustomEvent } from "@snap-mobile/snap-ui/dist/types/components";
import { ITEMS_PER_PAGE } from "../../../../constants";
import { MapAndCalcSum } from "helpers/map-and-reduce";
import { SpinnerContainer } from "shared-components/spinner";
import DeleteInvoice from "./modals/delete-invoice";
import { getBalanceDue } from "helpers/invoices";
import { ParticipantInvoice } from "types/invoice";
import { invoiceSortingOptions } from "./data";
import { isNullOrEmpty } from "helpers/null-or-empty";

type InovicesType = {
  participant: SpendGroupRoster[];
  canEditParticipants: boolean;
  loading?: boolean;
};

function Invoices({ participant, canEditParticipants, loading }: InovicesType) {
  const [selectedStatus, setSelectedStatus] = useState<string>("description");
  const sortingOptions: DropdownMenuItem[] = invoiceSortingOptions;
  const paidOptions: DropdownMenuItem[] = [
    { name: "Refund Payment", text: "Refund Payment", value: "Refund Payment" },
  ];
  const unpaidOptions: DropdownMenuItem[] = [
    {
      name: "Edit Invoice Amount",
      text: "Edit Invoice Amount",
      value: "Edit Invoice Amount",
    },
    {
      name: "Edit Due Date",
      text: "Edit Due Date",
      value: "Edit Due Date",
    },
    {
      name: "Delete Invoice",
      text: "Delete Invoice",
      value: "Delete Invoice",
    },
  ];
  const creditMemoOption: DropdownMenuItem = {
    name: "Edit Credit Memo",
    text: "Edit Credit Memo",
    value: "Edit Credit Memo",
  };

  const [modalData, setModalData] = useState<TRLVItems[]>([]);
  const { isOpen, toggle } = useModal();
  const { isOpen: refundOpen, toggle: refundToggle } = useModal();
  const { isOpen: editAmountOpen, toggle: editAmountToggle } = useModal();
  const { isOpen: editDueDateOpen, toggle: editDueDateToggle } = useModal();
  const { isOpen: editMemoModalOpen, toggle: editMemoModalToggle } = useModal();
  const { isOpen: deleteInvoiceModalOpen, toggle: deleteInvoiceModalToggle } =
    useModal();
  const [invoicesAmount, setInvoiceAmount] = useState(0);
  const [participantModal, setParticipantModal] = useState<SpendGroupRoster>();
  const [invoiceModal, setInvoiceModal] = useState<Maybe<SpendInvoice>>();
  const [currentPage, setCurrentPage] = useState(0);
  const [invoices, setInvoices] = useState<ParticipantInvoice[]>([]);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState("");

  useEffect(() => {
    const participantInvoices = participant.flatMap((groupRoster) => {
      return groupRoster.invoices?.map((invoice) => {
        const foundSeason = groupRoster.group?.seasons?.find(
          (season) => season?.id === groupRoster.seasonId
        );
        const hasNote = !isNullOrEmpty(invoice?.note);
        const reconciledNotes = invoice?.reconciledTransactions
          ?.map((rt) => rt?.note)
          .join(", ");
        let note = invoice?.note;
        if (hasNote && reconciledNotes?.length) {
          note = `${note}, ${reconciledNotes}`;
        } else if (!hasNote && reconciledNotes) {
          note = reconciledNotes;
        }

        return {
          ...invoice,
          status: getInvoiceStatus(invoice),
          groupName: groupRoster.group?.name,
          groupId: groupRoster.group?.id,
          seasonName: foundSeason?.name,
          seasonId: foundSeason?.id,
          seasonStartDate: foundSeason?.startDateAt,
          seasonEndDate: foundSeason?.endDateAt,
          participantName: groupRoster.roster?.name,
          rosterId: groupRoster.rosterId,
          note,
        };
      });
    });
    const sortedInvoices = participantInvoices.sort((a, b) => {
      return new Date(a?.dueDate ?? "") < new Date(b?.dueDate ?? "") ? -1 : 1;
    });

    setInvoiceAmount(sortedInvoices.length);
    setInvoices(sortedInvoices as ParticipantInvoice[]);
  }, [participant]);

  const getInvoiceStatus = (invoice: Maybe<SpendInvoice>) => {
    const apiStatus = invoice?.status?.replace("_", " ");
    if (apiStatus == null || apiStatus === "upcoming") {
      return "not paid";
    }
    return apiStatus;
  };

  const handleToggleModal = (invoice: ParticipantInvoice) => {
    let items: TRLVItems[] = [];
    setSelectedInvoiceId(invoice.id);
    items.push({ label: "Description", value: invoice.description || "" });
    items.push({
      label: "Participant",
      value: invoice.participantName,
      valueStyle: TableValueStyle.LINK,
    });
    items.push({
      label: "Group",
      value: invoice.groupName,
      valueStyle: TableValueStyle.LINK,
    });
    items.push({
      label: "Status",
      value: getInvoiceStatus(invoice),
      valueStyle: TableValueStyle.BADGE,
    });
    if (invoice.paid && invoice.paymentMethodSource) {
      items.push({
        label: "Payment Method",
        value: invoice.paymentMethodSource,
      });
      items.push({
        label: "Paid Date",
        value: FormatDate(invoice.paidDate, DateFormatSupported.Numbers),
      });
    }
    items.push({
      label: "Due Date",
      value: FormatDate(invoice.dueDate || "", DateFormatSupported.Numbers),
    });
    items.push({
      label: "Season",
      value: isNullOrEmpty(invoice.seasonName)
        ? `${FormatDate(
            invoice.seasonStartDate,
            DateFormatSupported.Numbers
          )} - ${FormatDate(
            invoice?.seasonEndDate,
            DateFormatSupported.Numbers
          )}`
        : invoice.seasonName,
    });
    items.push({
      label: "Invoice Amount",
      value: FormatMoney(invoice.amount || 0),
    });
    const bd = getBalanceDue(invoice);
    items.push({
      label: "Balance Due",
      value: FormatMoney(bd),
    });
    const discountAndCredits =
      (invoice.discountAmount || 0) +
      MapAndCalcSum(invoice.creditMemos ?? [], "creditApplied");
    if (discountAndCredits > 0) {
      items.push({
        label: "Credits & Discounts",
        value: FormatMoney(discountAndCredits),
      });
    }

    setModalData(items);
    toggle();
  };

  const prepLeftData = (invoice: ParticipantInvoice) => {
    let leftData: LabelValueObject[] = [];
    let option = !invoice.isOptional ? "Required" : "Not Required";
    leftData.push({
      key: "Group",
      value: invoice.groupName,
      className: "text-blue-600 text-sm font-bold",
    });
    leftData.push({
      key: "Season",
      value: isNullOrEmpty(invoice.seasonName)
        ? `${FormatDate(
            invoice.seasonStartDate,
            DateFormatSupported.Numbers
          )} - ${FormatDate(
            invoice?.seasonEndDate,
            DateFormatSupported.Numbers
          )}`
        : invoice.seasonName,
    });
    leftData.push({ key: "Optional", value: option });
    return leftData;
  };

  const prepRightData = (i: SpendInvoice) => {
    let rightData: LabelValueObject[] = [];
    rightData.push({
      key: "Due Date",
      value: FormatDate(i.dueDate!, DateFormatSupported.Numbers),
    });
    rightData.push({
      key: "Invoice Amount",
      value: FormatMoney(i.amount!),
    });
    const bd = getBalanceDue(i);
    rightData.push({ key: "Balance Due", value: FormatMoney(bd) });
    return rightData;
  };

  const getBadge = (status: string) => {
    return (
      <SnapBadge color={getBadgeColor(status)} className="ml-4 capitalize">
        {status}
      </SnapBadge>
    );
  };

  const handleSort = (selectedSort: string) => {
    const [field, order] = selectedSort.split(":");

    const sortedInvoices = invoices.sort((a, b) => {
      const valueA = a![field as keyof ParticipantInvoice];
      const valueB = b![field as keyof ParticipantInvoice];
      if (order === "ASC") {
        if (valueA === null || valueA === undefined) return 1;
        if (valueB === null || valueB === undefined) return -1;
        if (typeof valueA === "string" && typeof valueB === "string") {
          return valueA.localeCompare(valueB);
        }
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      } else {
        if (valueA === null || valueA === undefined) return -1;
        if (valueB === null || valueB === undefined) return 1;
        if (typeof valueA === "string" && typeof valueB === "string") {
          return valueB.localeCompare(valueA);
        }
        if (valueA < valueB) return 1;
        if (valueA > valueB) return -1;
        return 0;
      }
    });
    setCurrentPage(0);
    setInvoices(sortedInvoices);
  };

  return (
    <div className="card mb-10 lg:mb-0">
      <p className="text-lg font-semibold">Participant Invoices</p>
      <ShowingResults
        totalNumOfResults={invoicesAmount}
        numOfResultsBeingDisplayed={
          invoicesAmount <= 10
            ? invoicesAmount
            : ITEMS_PER_PAGE * currentPage + 10 >= invoicesAmount
            ? invoicesAmount
            : ITEMS_PER_PAGE * currentPage + 10
        }
        startingNumOfResults={
          invoicesAmount === 0 ? 0 : ITEMS_PER_PAGE * currentPage + 1
        }
      />
      <Divider className="mt-0 mb-6 lg:mb-4" isVisibleOnMobile />
      <div className="flex mb-4">
        <SnapIcon icon="sort-descending-line" size="sm" color="#64748B" />
        <div className="flex ml-1">
          <SnapDropDown
            buttonText="Sort"
            leftHang
            defaultValue={selectedStatus}
            options={sortingOptions}
            modalType="drawer"
            onSnap-dropdown-item-selected={(e) => {
              setSelectedStatus(e.detail.value);
              handleSort(e.detail.value);
            }}
          />
        </div>
      </div>

      {!loading ? (
        invoices
          .slice(
            currentPage * ITEMS_PER_PAGE,
            currentPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE
          )
          .map((invoice, idx) => {
            const roster = participant.find((roster) => {
              const foundInvoice = roster.invoices?.find(
                (i) => i?.id === invoice?.id
              );
              if (foundInvoice) {
                return roster;
              }
              return undefined;
            });
            if (!roster) {
              return null;
            }

            let menuItems = [...(invoice?.paid ? paidOptions : unpaidOptions)];
            let note: string[] = [];
            if (invoice?.note) {
              note.push(invoice.note);
            }
            if (invoice?.creditMemos?.length) {
              menuItems.push(creditMemoOption);
              invoice.creditMemos.forEach((m) => {
                if (!m.isArchived) {
                  let noteToAdd = `[CREDIT] ${m.title} ${
                    m.note && m.note !== "" ? `\n [Credit Memo] ${m.note}` : ""
                  }`;

                  if (note.length) {
                    noteToAdd = `\n ${noteToAdd}`;
                  }
                  note.push(noteToAdd);
                }
              });
            }
            const discountAndCredits =
              (invoice.discountAmount || 0) +
              MapAndCalcSum(invoice.creditMemos ?? [], "creditApplied");
            const amount = (invoice.amount || 0) - discountAndCredits;
            const refundableAmount = amount - invoice.balanceDue;

            return (
              <DataCard
                key={`${invoice?.id}_${idx}`}
                title={invoice?.description ?? ""}
                titleExtra={getBadge(invoice?.status ?? "")}
                titleAction={() => handleToggleModal(invoice!)}
                kvLeft={prepLeftData(invoice!)}
                kvRight={prepRightData(invoice!)}
                action={canEditParticipants ? 1 : 0}
                menuItems={
                  invoice?.isRefunded && refundableAmount <= 0 ? [] : menuItems
                }
                menuClickListener={(e) => {
                  setInvoiceModal(invoice);
                  setParticipantModal(roster);
                  switch (e) {
                    case "Refund Payment":
                      refundToggle();
                      break;
                    case "Edit Invoice Amount":
                      editAmountToggle();
                      break;
                    case "Edit Due Date":
                      editDueDateToggle();
                      break;
                    case "Edit Credit Memo":
                      editMemoModalToggle();
                      break;
                    case "Delete Invoice":
                      deleteInvoiceModalToggle();
                      break;
                  }
                }}
                footer={{ key: "Notes", value: note.toString() }}
              />
            );
          })
      ) : (
        <SpinnerContainer loading={loading} />
      )}

      <SnapPagination
        currentPage={currentPage}
        itemCount={invoicesAmount}
        pageSize={ITEMS_PER_PAGE}
        onSnap-pagination-page-changed={(
          event: SnapPaginationCustomEvent<number>
        ) => setCurrentPage(event.detail)}
      />
      <InvoiceDetails
        isOpen={isOpen}
        toggle={toggle}
        modalData={modalData}
        setModalData={setModalData}
        invoiceId={selectedInvoiceId}
      />
      {invoiceModal && participantModal && (
        <>
          {refundOpen && (
            <RefundPayment
              refundOpen={refundOpen}
              refundToggle={refundToggle}
              invoice={invoiceModal}
              participant={participantModal}
            />
          )}
          {editAmountOpen && (
            <EditInvoiceAmount
              editAmountOpen={editAmountOpen}
              editAmountToggle={editAmountToggle}
              participant={participantModal}
              invoice={invoiceModal}
            />
          )}
          {editDueDateOpen && (
            <EditDueDate
              editDueDateOpen={editDueDateOpen}
              editDueDateToggle={editDueDateToggle}
              participant={participantModal}
              invoice={invoiceModal}
            />
          )}
          {editMemoModalOpen && (
            <EditCreditMemo
              editMemoOpen={editMemoModalOpen}
              editMemoToggle={editMemoModalToggle}
              participant={participantModal}
              invoice={invoiceModal}
            />
          )}
          {deleteInvoiceModalOpen && (
            <DeleteInvoice
              deleteInvoiceModalOpen={deleteInvoiceModalOpen}
              deleteInvoiceModalToggle={deleteInvoiceModalToggle}
              participant={participantModal}
              invoice={invoiceModal}
            />
          )}
        </>
      )}
    </div>
  );
}

export default Invoices;
