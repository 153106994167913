import { ExportFile } from "graphql/generated";

export const downloadFile = ({
  content,
  fileName,
  fileType,
}: ExportFile & { fileType: string }) => {
  if (content == null || fileName == null || fileType == null) {
    return null;
  }
  let byteContent = atob(content);

  let byteArray = new Uint8Array(byteContent.length);
  for (let i = 0; i < byteContent.length; i++) {
    byteArray[i] = byteContent.charCodeAt(i);
  }

  const blob = new Blob([byteArray], { type: fileType });

  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export const exportToCsv = (contentToExport: ExportFile) => {
  exportToFile(contentToExport);
};

export const exportToFile = (contentToExport: ExportFile) => {
  const split = contentToExport.fileName?.split(".") || [];
  const extension =
    (split.length ? split.at(split?.length - 1) : undefined) || "na";
  const fileType = getMimeType(`.${extension}`);
  downloadFile({
    ...contentToExport,
    fileType,
  });
};

function getMimeType(extension: string) {
  const mimeTypes: any = {
    ".csv": "text/csv",
    ".txt": "text/plain",
    ".html": "text/html",
    ".css": "text/css",
    ".js": "application/javascript",
    ".json": "application/json",
    ".xml": "application/xml",
    ".pdf": "application/pdf",
    ".doc": "application/msword",
    ".docx":
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ".zip": "application/zip",
    ".jpeg": "image/jpeg",
    ".jpg": "image/jpeg",
    ".png": "image/png",
    ".gif": "image/gif",
    ".svg": "image/svg+xml",
    ".mp3": "audio/mpeg",
    ".wav": "audio/wav",
    ".mp4": "video/mp4",
    ".mkv": "video/x-matroska",
    ".xlsx":
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ".xls": "application/vnd.ms-excel",
    ".rar": "application/x-rar-compressed",
    ".exe": "application/octet-stream",
  };

  // Check if the provided extension is in the mimeTypes map
  return mimeTypes[extension.toLowerCase()] || "application/octet-stream"; // Default to octet-stream if not found
}
