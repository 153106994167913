
export const getPaypalClientId = () => {
    const DEV_PAYPAL_CLIENT_ID =
    'AVkgdPCdacthVN5bxdasTawhOgJEpK6WgTK25y9JpYU-_enKjF49Xg1xiBbFur5fdBg2ywigARFrxvNY';
  const LIVE_PAYPAL_CLIENT_ID = 'AZwTd9iQqFTEotYPfjGN79UO8SX0UJWosSGVYgnkhzmWKssVp2lQTBVA1yWitye0yP8c9ZleSJzwXIi5';  
    if (typeof window !== undefined) {
      const hostname = window.location.hostname;
      const isDev = [".dev", "localhost", ".staging", "ngrok"].some((v) => hostname.includes(v));
      return isDev ? DEV_PAYPAL_CLIENT_ID : LIVE_PAYPAL_CLIENT_ID;
    }
  
    return LIVE_PAYPAL_CLIENT_ID;
  }