import { V2 } from '@snap-mobile/payments-widget-client';
import {
  PayPalButtons,
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js';
import { PayPalButtonsComponentProps } from '@paypal/react-paypal-js';
import { usePaymentsWidgetContext } from '../context';
import { useCallback, useEffect } from 'react';
import styles from "./ExternalPaypalPaymentMethod.module.css"
import { getPaypalClientId } from '../helpers/paypal-helper';


export type ExternalPaypalPaymentMethodProps = Omit<
  PayPalButtonsComponentProps,
  'createOrder' | 'onApprove'
> & {
  product: string;
  secondaryId?: string;
  orderItems?: Array<{ name: string; value: number; }>;
  onSuccess?: (data: V2.PaypalOrderConfirmResponse) => void;
  metadata?: Record<string, unknown>;
  paypalClientId?: string;
  standalone?: boolean;
  // The error code for negative testing https://developer.paypal.com/api/rest/reference/orders/v2/errors/#link-captureorder
  mockCode?: {
    "order"?: string;
    "approved"?: string;
  }
};

/**
 * Props for `ExternalPaypalPaymentMethod` Component
 * @prop {string} product - The identifier for the product being purchased.
 * @prop {string} [secondaryId] - Optional secondary identifier that can be used in the payment process.
 * @prop {(data: V2.PaypalOrderConfirmResponse) => void} [onSuccess] - Optional callback function triggered after a successful payment.
 *                                           Receives the payment response data as an argument.
 * @prop {PayPalButtonsComponentProps} - Additional PayPal button properties, such as 'shippingPreference' and 'onCancel',
 *                                                      excluding 'createOrder' and 'onApprove', which are handled internally.
 *
 * This component provides a PayPal payment method for the payment widget. It integrates with PayPal's
 * React SDK to render PayPal buttons and handle the PayPal payment process.
 * The component checks the `selectedPaymentMethod` from `usePaymentsWidgetContext`.
 * When the selected payment method is 'external_paypal', the component renders the PayPal button using `PayPalScriptProvider`.
 *
 * Usage:
 *  <PaymentsWidgetProvider {...props}>
 *    <PaymentMethod
 *     onSuccess={(data) => console.log('Payment Successful', data)}
 *    >
 *       <ExternalPaypalPaymentMethod
 *        product="exampleProduct"
 *        onSuccess={(data) => console.log('Payment Successful', data)}
 *       />
 *       <SubmitButton />
 *    </PaymentMethod>
 * </PaymentsWidgetProvider>
 */
export const ExternalPaypalPaymentMethod = (
  props: ExternalPaypalPaymentMethodProps
) => {
  const { selectedPaymentMethod } = usePaymentsWidgetContext();

  if (!props.standalone && selectedPaymentMethod !== 'external_paypal') {
    return null;
  }

  const paypalClientId = props.paypalClientId || getPaypalClientId();

  return (
    <div
      className="external-paypal-payment-method"
      style={{
        fontFamily:
          'sans-serif !important; width: 100% !important; min-width: 100% !important',
      }}
    >
      <PayPalScriptProvider
        options={{
          clientId: paypalClientId,
          vault: true,
          components: 'buttons',
          disableFunding: 'bancontact,blik,paylater,card',          
        }}                
      >
        <div className={styles.externalPaymentMethodWrapper}>
          <InnerPaypalWidget {...props} />
        </div>
      </PayPalScriptProvider>
    </div>
  );
};

function InnerPaypalWidget(props: ExternalPaypalPaymentMethodProps) {
  const { product, secondaryId, onSuccess, style, orderItems, mockCode, onCancel, ...buttonProps } = props;

  const [{ isPending }] = usePayPalScriptReducer();

  const { paymentData, processing, setProcessing, setError } = usePaymentsWidgetContext();

  const createOrder: PayPalButtonsComponentProps["createOrder"] = useCallback(async () => {
    setProcessing(true);
    const data: V2.CreatePaypalOrderParams = {
      externalPaymentId: paymentData.externalPaymentId,
      product: product || 'snap',
      secondaryId: secondaryId,
      snapAmount: paymentData.snapAmount,
      totalAmount: paymentData.totalAmount,
      orderItems: orderItems?.length ? orderItems : undefined,
      returnUrl: paymentData.returnUrl ?? window.location.href,
      cancelUrl: paymentData.cancelUrl ?? window.location.href,
      mockCode: mockCode?.["order"] || undefined
    };
    try {
      const resp = await V2.paypalCreateOrder(data);
      if (!resp.id) throw new Error("Can not create order");
      return resp.id;
    } catch (e: any) {      
      setError({
        type: 'processing',
        message: e.message || 'Error creating order',
      });
      setProcessing(false)
      throw e;
    }
  }, [paymentData, product, secondaryId, setError]);

  const onApprove: PayPalButtonsComponentProps["onApprove"] = async (data) => {
    setProcessing(true);
    const { orderID, ...rest } = data;
    try {
      const resp = await V2.paypalApproveOrder(orderID!, {
        ...rest,
        externalPaymentId: paymentData.externalPaymentId,
        metadata: paymentData.metadata,
        mockCode: mockCode?.["approved"] || undefined
      });
      if (resp) {
        onSuccess?.(resp);
      }

    } catch (e: any) {
      console.error(e);
      setError({
        type: 'processing',
        message: e.message || 'Error approving order',
      });
    }
    setProcessing(false);
  };

  if (isPending) {
    return <div className="loading">Loading...</div>;
  } else {
    return (
      <div style={{ marginTop: '5px' }}>
        <PayPalButtons
          className={`snap-paypal-button ${styles.snapPaypalButton}`}
          {...buttonProps}
          onCancel={(data, actions) => {
            setProcessing(false);
            onCancel && onCancel?.(data, actions);
          }}
          disabled={!!processing || buttonProps.disabled}
          createOrder={createOrder}
          onApprove={onApprove}
          style={{            
            disableMaxWidth: true,
            layout: 'vertical',
            tagline: false,
            ...(style || {}),
          }}          
          key={`${paymentData.externalPaymentId}-${paymentData.totalAmount}`}
        />
      </div>
    );
  }
}
