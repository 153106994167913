import GroupContext from "context/group-context";
import {
  SpendInvoice,
  SpendSortInput,
  useSpendGroupByIdLazyQuery,
} from "graphql/generated";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import useModal from "hooks/use-modal";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InvoiceDetails from "shared-components/modal/invoice-details";
import { TableValueStyle } from "shared-components/table-row-label-value";
import { SnapPagination } from "suit";
import { ITEMS_PER_PAGE } from "../../../constants";
import Cards from "./card";
import Table from "./table";
import { MapAndCalcSum } from "helpers/map-and-reduce";
import SeasonContext from "context/season-context";

type InvoiceListItemProps = {
  listItems: SpendInvoice[] | undefined;
  totalItems: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  toggleSort: (field: string) => void;
  currentSort: SpendSortInput | undefined;
  loadingInvoices?: boolean;
  exporting?: boolean;
};

export type TRLVItems = {
  label: string;
  value: string;
  valueStyle?: TableValueStyle;
  className?: string;
  onClick?: () => void;
};

const InvoiceListItem = ({
  listItems,
  totalItems,
  page,
  setPage,
  toggleSort,
  currentSort,
  loadingInvoices,
  exporting,
}: InvoiceListItemProps) => {
  const navigate = useNavigate();
  const group = useContext(GroupContext);
  const season = useContext(SeasonContext);

  const [selectedInvoiceId, setSelectedInvoiceId] = useState("");
  const [selectedSeasonId, setSelectedSeasonId] = useState("");
  const [modalData, setModalData] = useState<TRLVItems[]>([]);
  const { isOpen, toggle } = useModal();

  const [getGroup, { data: groupData, loading: loadingGroup }] =
    useSpendGroupByIdLazyQuery();

  useEffect(() => {
    if (!loadingGroup && groupData) {
      const selectedGroup = groupData.spendGroupById;
      group?.setAndStoreActiveGroup({
        ...selectedGroup,
      });
      navigate("/groups/collections");
      season?.handleSelectedSeason({
        seasonId: selectedSeasonId,
        seasonsToSet: selectedGroup?.seasons,
      });
    }
    // eslint-disable-next-line
  }, [loadingGroup, groupData]);

  const handleToggleModal = (item: SpendInvoice) => {
    let items: TRLVItems[] = [];
    const playerName = `${item.groupRoster?.roster?.name}`;
    setSelectedInvoiceId(item.id ?? "");
    items.push({
      label: "Description",
      value: item.description || "",
    });
    items.push({
      label: "Participant",
      value: playerName,
      valueStyle: TableValueStyle.LINK,
      onClick: () => {
        handleNavigateParticipant(item.groupRoster?.roster?.id ?? "");
      },
    });
    items.push({
      label: "Group",
      value: item.groupRoster?.group?.name || "",
      valueStyle: TableValueStyle.LINK,
      onClick: () => {
        handleNavGroup(
          item.groupRoster?.group?.id ?? "",
          item.groupRoster?.season?.id ?? ""
        );
      },
    });
    items.push({
      label: "Status",
      value: item.status?.replace("_", " ") ?? "",
      valueStyle: TableValueStyle.BADGE,
    });
    items.push({
      label: "Due Date",
      value: FormatDate(item.dueDate || "", DateFormatSupported.Numbers),
    });
    items.push({
      label: "Season",
      value: item.groupRoster?.season?.name || "",
    });
    items.push({
      label: "Invoice Amount",
      value: FormatMoney(item.amount || 0),
    });
    items.push({
      label: "Balance Due",
      value: FormatMoney(item.balanceDue || 0),
    });
    const discountAndCredits =
      (item.discountAmount || 0) +
      MapAndCalcSum(item.creditMemos ?? [], "creditApplied");
    if (discountAndCredits > 0) {
      items.push({
        label: "Credits & Discounts",
        value: FormatMoney(discountAndCredits),
      });
    }
    setModalData(items);
    toggle();
  };

  const handleNavGroup = (groupId: string, seasonId: string) => {
    getGroup({
      variables: {
        spendGroupByIdId: groupId,
      },
    });
    setSelectedSeasonId(seasonId);
  };

  const handleNavigateParticipant = (userId: string) => {
    navigate(`/people/participants/participant-details/${userId}`);
  };

  return (
    <>
      {
        <>
          <Table
            listItems={listItems}
            handleToggleModal={handleToggleModal}
            handleNavGroup={handleNavGroup}
            handleNavigateParticipant={handleNavigateParticipant}
            toggleSort={toggleSort}
            currentSort={currentSort}
            loadingInvoices={loadingInvoices}
            exporting={exporting}
          />
          <Cards
            listItems={listItems}
            handleToggleModal={handleToggleModal}
            handleNavGroup={handleNavGroup}
            handleNavigateParticipant={handleNavigateParticipant}
            loadingInvoices={loadingInvoices}
            exporting={exporting}
          />
        </>
      }
      <SnapPagination
        currentPage={page}
        itemCount={totalItems}
        pageSize={ITEMS_PER_PAGE}
        onSnap-pagination-page-changed={(e) => {
          setPage(e.detail);
        }}
      />
      <InvoiceDetails
        isOpen={isOpen}
        toggle={toggle}
        modalData={modalData}
        invoiceId={selectedInvoiceId}
        setModalData={setModalData}
      />
    </>
  );
};

export default InvoiceListItem;
