import { InternalRefetchQueriesInclude } from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import GroupContext from "context/group-context";
import SeasonContext from "context/season-context";
import ToastContext from "context/toast-context";
import { useSpendBudgetDeleteMutation } from "graphql/generated";
import {
  GETorganization_BUDGET_SUMMARY,
  GET_SPEND_BUDGET_SUMMARY,
} from "graphql/queries/budgets";
import { GET_GROUPS } from "graphql/queries/group";
import { isNullOrEmpty } from "helpers/null-or-empty";
import { useContext, useEffect, useState } from "react";
import FixedModal from "shared-components/fixed-modal/fixed-modal";
import CustomModal, { BtnState, BtnType } from "shared-components/modal";
import { SnapIcon, SnapSelectMenu } from "suit";
import { selectedBudgetItem } from "types/budgets";

type DeleteBudgetProps = {
  isOpen: boolean;
  toggle: () => void;
  dataToDelete: selectedBudgetItem;
  budgetItems: selectedBudgetItem[];
};

function DeleteBudget({
  isOpen,
  toggle,
  dataToDelete,
  budgetItems,
}: DeleteBudgetProps) {
  const group = useContext(GroupContext);
  const season = useContext(SeasonContext);
  const toast = useContext(ToastContext);

  const [isBtnActive, setIsBtnActive] = useState(true);
  const [isDeleteable, setIsDeleteable] = useState(true);
  const [budgetOptions, setBudgetItems] = useState<SnapSelectMenuOption[]>([]);
  const [replacementBudgetItem, setReplacementBudgetItem] = useState("");

  const refetchContent: InternalRefetchQueriesInclude = [
    !group?.activeGroup
      ? { query: GETorganization_BUDGET_SUMMARY, fetchPolicy: "network-only" }
      : {
          query: GET_SPEND_BUDGET_SUMMARY,
          variables: {
            groupId: group.activeGroup.id,
            seasonId: season?.selectedSeason?.id,
          },
          fetchPolicy: "network-only",
        },
    {
      query: GET_GROUPS,
      fetchPolicy: "network-only",
    },
  ];
  const [
    deleteBudgetItem,
    { loading: deleteLoading, data: deleteData, error: deleteError },
  ] = useSpendBudgetDeleteMutation({
    refetchQueries: refetchContent,
  });

  useEffect(() => {
    if (!deleteLoading) {
      if (deleteData) {
        toast?.setToast({
          message: "Budget Item Deleted Successfully",
          type: "success",
        });
        toggle();
        setIsBtnActive(true);
        setIsDeleteable(true);
      }
      if (deleteError) {
        setIsBtnActive(true);
        setIsDeleteable(false);
      }
    }

    // eslint-disable-next-line
  }, [deleteLoading, deleteData]);

  useEffect(() => {
    const options: SnapSelectMenuOption[] = [];
    budgetItems.forEach((bi) => {
      if (bi.budgetId !== dataToDelete.budgetId) {
        options.push({
          name: bi.budgetItem,
          value: bi.budgetId,
          selected: false,
        });
      }
    });
    setBudgetItems(options);
  }, [budgetItems, dataToDelete]);

  let btn1: BtnType = {
    text: "Delete",
    btnStyle: "danger",
    onClick: () => {
      setIsBtnActive(false);
      deleteBudgetItem({
        variables: {
          spendBudgetDeleteId: dataToDelete.budgetId!,
        },
        refetchQueries: refetchContent,
      });
    },
    btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
  };
  let btn2: BtnType = { text: "Cancel", btnStyle: "tertiary", onClick: toggle };
  return isDeleteable ? (
    <FixedModal
      isOpen={isOpen}
      toggle={toggle}
      title={""}
      btn1={btn1}
      btn2={btn2}
    >
      <div className="flex flex-col justify-center gap-2 mx-auto text-center w-full modal-card pt-0">
        <SnapIcon
          className="mb-2"
          icon="exclamation-solid"
          state="rounded"
          size="xl"
          color="#EF4444"
          bgColor="#FEE2E2"
        />
        <h3 className="font-semibold">Delete Budget Item</h3>
        <p className="text-sm text-center font-medium text-gray-500">
          Are you sure you want to delete{" "}
          {dataToDelete.budgetItem ?? "this item"}?
        </p>
      </div>
    </FixedModal>
  ) : (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      title={"Re-assign Budget Item"}
      btn1={{
        text: "Submit",
        onClick: () => {
          setIsBtnActive(false);
          if (isNullOrEmpty(replacementBudgetItem)) {
            toast?.setToast({
              message: "Please select a replacement budget item.",
              type: "danger",
            });
            setIsBtnActive(true);
          } else {
            deleteBudgetItem({
              variables: {
                spendBudgetDeleteId: dataToDelete.budgetId!,
                replace: replacementBudgetItem,
              },
            });
          }
        },
        btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
      }}
      btn2={{
        text: "Cancel",
        onClick: () => {
          setIsBtnActive(true);
          setIsDeleteable(true);
          toggle();
        },
      }}
    >
      <div className="modal-card mb-28">
        <p>
          This budget item is associated to 1 or more invoices and or payment
          schedule invoices. Please re-assign before deleting.
        </p>
        <p>All reconciled transactions will be unreconciled.</p>
        <p className="my-4">
          Pleaes select a budget item to replace the deleted budget item.
        </p>
        <SnapSelectMenu
          selectMenuOptions={budgetOptions}
          onSnap-select-menu-updated={(e) => {
            setReplacementBudgetItem(
              e.detail.find((d) => d.selected)?.value ?? ""
            );
          }}
        />
      </div>
    </CustomModal>
  );
}

export default DeleteBudget;
