import { gql } from "@apollo/client";

export const CREATE_SESSION = gql`
  mutation SpendSessionCreate($inviteId: String) {
    spendSessionCreate(inviteId: $inviteId) {
      url
      status
      expiresAt
      message
      id
      userId
      inviteId
      isDismissed
      role {
        groupId
        id
        name
        organizationId
        permissions
        isArchived
        seasonId
      }
      pendingInvites {
        id
        type
        groupId
        groupName
        rosterName
        seasonName
        organizationId
        organizationName
        expiresAt
        status
        isDismissed
        seasonId
      }
    }
  }
`;

export const DELETE_SESSION = gql`
  mutation SpendSessionDelete {
    spendSessionDelete {
      id
    }
  }
`;

export const REFRESH_SESSION = gql`
  mutation SpendSessionRefresh($sessionId: String!) {
    spendSessionRefresh(sessionId: $sessionId) {
      id
    }
  }
`;

export const LOGOUT = gql`
  mutation Mutation {
    logout
  }
`;

export const UNIT_APPLICATION = gql`
  mutation SpendUnitApplication {
    spendUnitApplication {
      url
    }
  }
`;
