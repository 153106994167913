import React, { FormEvent, forwardRef, useCallback, useImperativeHandle } from 'react';
import { V2 } from '@snap-mobile/payments-widget-client';
import { usePaymentsWidgetContext } from '../context';
import { PaypalButton } from './custom-express-checkout/buttons/PaypalButton';

export type ExistingPaypalPaymentMethodWidgetProps = React.PropsWithChildren<{
  paymentMethodId: string;
  product: string;
  secondaryId?: string;
  renderSubmitButton?: React.ReactNode;
  orderItems?: Array<{ name: string; value: number }>;
  onSuccess?: (data: V2.PaypalOrderConfirmResponse) => void | Promise<void>;
}>;

export type ExistingPaypalPaymentMethodRef = {
  submit: () => void;
};

/**
 * Props for `ExistingPaypalPaymentMethod` Component
 *
 * @prop {string} paymentMethodId - An object representing the payment method to be used.
 *                                       This is a mandatory prop for processing the payment
 * @prop {React.ReactNode} renderSubmitButton - Optional custom node to be rendered as the submit button.
 *                                              If not provided, a default submit button is used.
 * @prop {string} product - The identifier for the product being purchased.
 * @prop {string} [secondaryId] - Optional secondary identifier that can be used in the payment process.
 * @prop {(data: V2.PaypalOrderConfirmResponse) => void} [onSuccess] - Optional callback function triggered after a successful payment.
 *                                           Receives the payment response data as an argument.
 ** Ref Methods:
 * @method submit - Triggers the payment submission process.
 * The `ExistingPaypalPaymentMethod` component integrates with custom hooks and context for managing payment
 * processing. It renders a form for submitting an existing payment method. The form submission
 * involves setting the processing state, creating a customer, and confirming the payment using the
 * provided method. If the payment confirmation is successful, the `onSuccess` callback is triggered.
 *
 * * Usage:
 * <PaymentsWidgetProvider {...props}>
 * <ExistingPaypalPaymentMethod
 *   paymentMethodId={yourPaymentMethodId}
 *   product="exampleProduct"
 *   renderSubmitButton={<CustomSubmitButton />}
 *   onSuccess={(data) => console.log('Payment Successful', data)}
 * />
 * </PaymentsWidgetProvider>
 */
export const ExistingPaypalPaymentMethod = forwardRef<
  ExistingPaypalPaymentMethodRef,
  ExistingPaypalPaymentMethodWidgetProps
>(
  (
    {
      paymentMethodId,
      renderSubmitButton,
      product,
      secondaryId,
      onSuccess,
      orderItems,
    }: ExistingPaypalPaymentMethodWidgetProps,
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      submit() {
        onSubmit();
      },
    }));

    const { paymentData, setProcessing, setError } = usePaymentsWidgetContext();

    const createOrder = useCallback(async () => {
      const data: V2.CreatePaypalOrderParams & {
        metadata?: Record<string, unknown>;
      } = {
        externalPaymentId: paymentData.externalPaymentId,
        product: product || 'snap',
        secondaryId: secondaryId,
        snapAmount: paymentData.snapAmount,
        totalAmount: paymentData.totalAmount,
        orderItems: orderItems?.length ? orderItems : undefined,
        returnUrl: paymentData.returnUrl ?? window.location.href,
        cancelUrl: paymentData.cancelUrl ?? window.location.href,
        paymentMethodVaultId: paymentMethodId,
        metadata: paymentData.metadata,
      };
      try {
        const resp = await V2.paypalReusePaymentMethod(data);
        onSuccess && (await onSuccess(resp));
        return resp.id;
      } catch (e: any) {
        console.error(e);
        setError({
          type: 'processing',
          message: e.message || 'Error creating order',
        });
        return null;
      }
    }, [paymentData, product, secondaryId, setError, paymentMethodId]);

    const onSubmit = async (e?: FormEvent) => {
      e?.preventDefault();
      setProcessing(true);
      await createOrder()
      setProcessing(false);
    };

    return (
      <form id="existing-paypal-payment-method" onSubmit={onSubmit}>        
        { renderSubmitButton || (
          <PaypalButton onClick={onSubmit} />
        ) }
      </form>
    );
  }
);
