import ProgramContext from "context/program-context";
import {
  SpendGroup,
  SpendLegacyTransaction,
  useSpendTransactionsLegacyQuery,
} from "graphql/generated";
import { useContextStrict } from "helpers/context-strict";
import { FormatDate, DateFormatSupported } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { getBadgeColor } from "helpers/status-color";
import useModal from "hooks/use-modal";
import { useState, useEffect } from "react";
import DataCard from "shared-components/data-card";
import Divider from "shared-components/divider";
import TransactionDetails from "shared-components/modal/transaction-details.tsx";
import ShowingResults from "shared-components/showing-results";
import { SpinnerContainer } from "shared-components/spinner";
import { VerticalValueStyle } from "shared-components/vertical-label-value";
import { SnapBadge, SnapIcon, SnapPagination } from "suit";
import { LabelValueObject } from "types/label-value-object";
import ApplyToPlayerInvoice from "shared-components/modal/reconciliation/apply-to-player-invoice";
import ReconcileAs from "shared-components/modal/reconciliation/reconcile-as";
import UndoApplyToPlayerInvoice from "shared-components/modal/reconciliation/undo-apply-to-player-invoice";
import { legacyTrx } from "helpers/transaction";
import AddBudgetItem from "shared-components/modal/reconciliation/add-budget-item";

const ITEMS_PER_PAGE = 10;

function LegacyTransactions({
  legacyAccountId,
  menuItems,
  canUpdateBudgetItem,
  group,
}: {
  legacyAccountId: string;
  menuItems: any[];
  canUpdateBudgetItem: boolean;
  group?: SpendGroup;
}) {
  const program = useContextStrict(ProgramContext);
  const [focusOnTransaction, setFocusOnTransaction] = useState<
    SpendLegacyTransaction | undefined
  >(undefined);
  const {
    isOpen: transactionDetailsOpen,
    toggle: transactionDetailsToggle,
    tabSelectedValue,
    setSelectedTab,
  } = useModal();
  const [selectedTran, setSelectedTran] = useState<
    SpendLegacyTransaction | any | undefined
  >(undefined);
  const [selectedModal, setSelectedModal] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const { isOpen: applyToPlayerOpen, toggle: applyToPlayerToggle } = useModal();
  const { isOpen: reconcileIncomeOpen, toggle: reconcileIncomeToggle } =
    useModal();
  const { isOpen: reconcileExpenseOpen, toggle: reconcileExpenseToggle } =
    useModal();
  const { isOpen: unApplyToPlayerOpen, toggle: unApplyToPlayerToggle } =
    useModal();
  const { isOpen: incomeBudgetOpen, toggle: incomeBudgetToggle } = useModal();
  const { isOpen: expenseBudgetOpen, toggle: expenseBudgetToggle } = useModal();
  const [totalCompleted, setTotalCompleted] = useState(0);
  const [completedTransactions, setCompletedTransactions] = useState<
    SpendLegacyTransaction[]
  >([]);

  const { loading: loadingCompleted, data: completedData } =
    useSpendTransactionsLegacyQuery({
      variables: {
        groupId: group?.id ?? undefined,
        pagination: {
          limit: ITEMS_PER_PAGE,
          offset: pageIndex * ITEMS_PER_PAGE,
        },
      },
    });

  useEffect(() => {
    if (
      !loadingCompleted &&
      completedData &&
      completedData.spendTransactionsLegacy
    ) {
      setTotalCompleted(completedData.spendTransactionsLegacy?.count || 0);
      const transactions = (completedData.spendTransactionsLegacy
        ?.transactions || []) as SpendLegacyTransaction[];
      setCompletedTransactions(transactions);
    }
  }, [loadingCompleted, completedData]);

  const prepLeftData = (transaction: SpendLegacyTransaction) => {
    let leftData: LabelValueObject[] = [];
    leftData.push({
      key: "Type",
      value: legacyTrx.getType(transaction, legacyAccountId),
      className: "capitalize",
    });
    leftData.push({
      key: "Status",
      value: legacyTrx.getStatus(transaction),
      valueStyle: VerticalValueStyle.Text,
      className: "capitalize",
    });
    if (transaction.note?.content != null) {
      leftData.push({
        key: "Note",
        value: transaction.note.content,
      });
    }
    return leftData;
  };

  const prepRightData = (transaction: SpendLegacyTransaction) => {
    let rightData: LabelValueObject[] = [];

    rightData.push({
      key: "Date",
      value: FormatDate(
        transaction.effective || "",
        DateFormatSupported.Numbers
      ), //TODO - Update Due Date
    });
    const factor = transaction.source === legacyAccountId ? -1 : 1;
    const transactionAmount =
      (transaction.amount || 0) - Math.abs(transaction.snapAmount || 0);
    const transactionValue = transactionAmount * factor;
    let amountColor = transactionValue <= 0 ? "text-red-600" : "text-gray-800";
    const isReturned = transaction.status === "SETTLED_RETURNED";
    rightData.push({
      key: "Amount",
      value: FormatMoney(transactionValue),
      valueStyle: isReturned
        ? VerticalValueStyle.ReturnText
        : VerticalValueStyle.ColorText,
      valueColor: isReturned ? "text-gray-600" : amountColor,
    });

    return rightData;
  };

  const prepTitleExtra = (transaction: SpendLegacyTransaction) => {
    const recData = legacyTrx.getReconStatus(transaction);
    const partiallyReconciled = recData.status === "Partially Reconciled";
    return (
      <div className="flex">
        {transaction.reconciliation != null && partiallyReconciled && (
          <SnapBadge color={getBadgeColor("partially_reconciled")} class="ml-5">
            Partially Reconciled
          </SnapBadge>
        )}
        {transaction.reconciliation == null && (
          <SnapBadge color={getBadgeColor("Unreconciled")} class="ml-5">
            Unreconciled
          </SnapBadge>
        )}
        {transaction.attachments && transaction.attachments.length > 0 && (
          <SnapIcon icon="paper-clip-solid" color="#2563EB" />
        )}
      </div>
    );
  };
  const menuItemFilter = (transaction: SpendLegacyTransaction): any[] => {
    const paymentType = legacyTrx.getType(transaction, legacyAccountId);
    const reconData = legacyTrx.getReconStatus(transaction);
    const reconciled = reconData.status;
    let reconType = reconData.reconciledType;
    const filteredMenuItems = menuItems.filter((menuItem) => {
      if (reconciled === "Unreconciled") {
        // menu items that are not reconciled
        return (
          menuItem.type.includes(paymentType.toLowerCase()) &&
          menuItem.key.includes(reconciled) &&
          !menuItem.isPending
        );
      } else {
        return (
          menuItem.type.includes(paymentType.toLowerCase()) &&
          menuItem.key.includes(reconciled) &&
          reconType === menuItem.secondaryKey
        );
      }
    });
    return filteredMenuItems;
  };
  const modalTogglerFromMenuTitle = (menuTitle: any): void => {
    switch (menuTitle) {
      case "Apply to Participant Invoice":
        applyToPlayerToggle();
        break;
      case "Undo Apply to Participant Invoice":
        unApplyToPlayerToggle();
        break;
      case "Reconcile as Income":
        reconcileIncomeToggle();
        break;
      case "Reconcile as Expense":
        reconcileExpenseToggle();
        break;
      // case "Add Income Budget Item":
      //   incomeBudgetToggle();
      //   break;
      // case "Add Expense Budget Item":
      //   expenseBudgetToggle();
      //   break;
      case "View/Edit Reconciled Income":
        reconcileIncomeToggle();
        break;
      case "View/Edit Reconciled Expense":
        reconcileExpenseToggle();
        break;
    }
  };
  return (
    <div className={"mt-2"}>
      <ShowingResults
        totalNumOfResults={totalCompleted}
        numOfResultsBeingDisplayed={
          totalCompleted <= 10
            ? completedData?.spendTransactionsLegacy?.transactions?.length
            : ITEMS_PER_PAGE * pageIndex + 10 >= totalCompleted
            ? totalCompleted
            : ITEMS_PER_PAGE * pageIndex + 10
        }
        startingNumOfResults={
          completedData?.spendTransactionsLegacy?.transactions?.length === 0
            ? 0
            : ITEMS_PER_PAGE * pageIndex + 1
        }
      />
      <br className={"mt-4 block"} />
      <p className="text-sm font-medium text-gray-500 mb-4">
        Complete
        <span className="ml-2 inline-block">{totalCompleted}</span>
      </p>
      <Divider className="mb-4" />
      <div className={"relative"}>
        {completedTransactions.map((transaction, idx) => {
          return (
            <DataCard
              title={legacyTrx.getTitle(transaction, legacyAccountId)}
              key={transaction.id || idx}
              titleExtra={prepTitleExtra(transaction)}
              titleAction={() => {
                setSelectedTran(transaction);
                transactionDetailsToggle();
              }}
              kvLeft={prepLeftData(transaction)}
              kvRight={prepRightData(transaction)}
              action={canUpdateBudgetItem ? 1 : 0}
              menuItems={menuItemFilter(transaction)}
              menuClickListener={(title) => {
                setFocusOnTransaction(transaction);
                modalTogglerFromMenuTitle(title);
              }}
            />
          );
        })}
        <SpinnerContainer loading={loadingCompleted} />
      </div>
      <div className="pt-5">
        <SnapPagination
          itemCount={totalCompleted}
          currentPage={pageIndex}
          pageSize={ITEMS_PER_PAGE}
          onSnap-pagination-page-changed={(e) => {
            setPageIndex(e.detail);
          }}
        />
      </div>
      {focusOnTransaction && applyToPlayerOpen && (
        <ApplyToPlayerInvoice
          applyToPlayerOpen={applyToPlayerOpen}
          applyToPlayerToggle={applyToPlayerToggle}
          transaction={focusOnTransaction}
          startAt={0}
        />
      )}

      {focusOnTransaction && (reconcileIncomeOpen || reconcileExpenseOpen) && (
        <ReconcileAs
          type={group == null ? "Program" : "Group"}
          transaction={{
            ...focusOnTransaction,
            legacyAccountId: legacyAccountId,
            totalReconciled:
              legacyTrx.getReconStatus(focusOnTransaction).reconciledAmount,
          }}
          directionType={reconcileIncomeOpen ? "Income" : "Expense"}
          reconcileOpen={reconcileIncomeOpen || reconcileExpenseOpen}
          reconcileToggle={() => {
            if (reconcileIncomeOpen) reconcileIncomeToggle();
            else reconcileExpenseToggle();
          }}
        />
      )}
      {focusOnTransaction && unApplyToPlayerOpen && (
        <UndoApplyToPlayerInvoice
          undoApplyToPlayerOpen={unApplyToPlayerOpen}
          undoApplyToPlayerToggle={unApplyToPlayerToggle}
          transaction={{
            ...focusOnTransaction,
            totalReconciled:
              legacyTrx.getReconStatus(focusOnTransaction).reconciledAmount,
          }}
        />
      )}

      {focusOnTransaction && (incomeBudgetOpen || expenseBudgetOpen) && (
        <AddBudgetItem
          type={group == null ? "Program" : "Group"}
          directionType={incomeBudgetOpen ? "Income" : "Expense"}
          budgetItemOpen={incomeBudgetOpen || expenseBudgetOpen}
          budgetItemToggle={() => {
            if (incomeBudgetOpen) incomeBudgetToggle();
            else expenseBudgetToggle();
          }}
          transaction={{
            ...focusOnTransaction,
            direction: legacyTrx.getDirection(
              focusOnTransaction,
              legacyAccountId
            ),
            totalReconciled:
              legacyTrx.getReconStatus(focusOnTransaction).reconciledAmount,
          }}
        />
      )}
      {selectedTran && transactionDetailsOpen && (
        <TransactionDetails
          isOpen={transactionDetailsOpen}
          toggle={transactionDetailsToggle}
          tabSelectedValue={tabSelectedValue}
          selectedTran={selectedTran}
          selectedModal={selectedModal}
          setSelectedModal={setSelectedModal}
          setSelectedTab={setSelectedTab}
          canEditNotesAndAttachments={false}
          perspectiveId={program?.organization?.id ?? undefined}
          legacyAccountId={legacyAccountId}
        />
      )}
    </div>
  );
}

export default LegacyTransactions;
