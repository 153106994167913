import DragAndDrop from "shared-components/drag-and-drop";

function UnitFileUpload() {
  return (
    <div className="card">
      <h1 className="my-4">Application Documents File Uploader</h1>
      <DragAndDrop
        label={""}
        type={"image"}
        paymentId={""}
        fromSettings={true}
      />
    </div>
  );
}

export default UnitFileUpload;
