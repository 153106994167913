import { useMutation, useQuery } from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import GroupContext from "context/group-context";
import ProgramContext from "context/program-context";
import SplitIOContext from "context/splitio-context";
import UserContext from "context/user-context";
import {
  Maybe,
  SpendAccountLimitsResponse,
  SpendBankAccount,
  SpendGroup,
} from "graphql/generated";
import { GROUP_EXTERNAL_TRANSFER } from "graphql/mutations/group";
import {
  ORG_ACCOUNT_TRANSFER,
  ORG_EXTERNAL_TRANSFER,
} from "graphql/mutations/organization";
import {
  GET_GROUPS_WITH_NAME_ID,
  GET_GROUP_BANK_ACCOUNTS,
} from "graphql/queries/group";
import {
  GET_ORGANIZATION_ACCOUNTS,
  GET_ORG_BANK_ACCOUNTS,
} from "graphql/queries/organization";
import { FormatMoney } from "helpers/format-money";
import { ToastProp } from "hooks/use-toast";
import React, { useContext, useEffect, useState } from "react";
import ExternalTransfer from "shared-components/banking/transfer/external-transfer";
import CustomModal, { BtnState } from "shared-components/modal";
import { transferError } from "types/errors";
import { SpendPermissions } from "types/roles-permissions";
import { useContextStrict } from "../../../helpers/context-strict";
import InternalGroup from "./internal-group";
import InternalProgram from "./internal-program";

type TransferProps = {
  type: "program" | "group";
  isTransferOpen: boolean;
  transferToggle: () => void;
  toast: {
    toggleToast: () => void;
    message: string;
    title?: string;
    type: "danger" | "success" | "warning" | "info";
    setToastProps: React.Dispatch<React.SetStateAction<ToastProp>>;
  };
  hasLinkedAccount: boolean;
  openPlaid: () => void;
  unitAmount?: Maybe<number>;
  linkedAccount: SpendBankAccount | undefined;
  isArchived?: Boolean;
  settleCharge?: boolean;
};

function Transfer({
  type,
  isTransferOpen,
  transferToggle,
  toast,
  hasLinkedAccount,
  openPlaid,
  unitAmount,
  linkedAccount,
  isArchived,
  settleCharge,
}: TransferProps) {
  const splits = useContextStrict(SplitIOContext);
  useEffect(() => {
    setSe503flag(splits.isTreatmentOn(splits.act.se503));
  }, [splits]);

  const programContext = useContext(ProgramContext);
  const groupContext = useContext(GroupContext);
  const userContext = useContextStrict(UserContext);
  const userContextRole = useContext(UserContext)?.getRole();
  const activeGroupId = groupContext?.activeGroup?.id;
  const activeTabStyle = "border-b-2 border-gray-800";
  const tabOptions = ["Internal", "External"];
  const [selectedTab, setSelectedTab] = useState(0);
  const [hasErrorObj, setHasErrorObj] = useState<transferError>({
    toError: false,
    fromError: false,
    noteError: false,
    amountError: false,
    availableError: false,
    macthingError: false,
  });
  const canExternalTransfer =
    userContext.checkSpendPermission(SpendPermissions.programBankUpdate) ??
    false;

  const [
    accountTransfer,
    { loading: loadingTransfer, data: transferData, error: transferError },
  ] = useMutation(ORG_ACCOUNT_TRANSFER, {
    refetchQueries: [
      "SpendGroupsFiltered",
      "SpendTransactionsCompletedWhered",
      "SpendTransactionsPendingWhered",
      { query: GET_ORGANIZATION_ACCOUNTS, fetchPolicy: "network-only" },
    ],
    fetchPolicy: "network-only",
  });
  const [
    extAccountTransfer,
    {
      loading: loadingExAccountTransfer,
      data: exAccountData,
      error: exAccountError,
    },
  ] = useMutation(
    type === "program" ? ORG_EXTERNAL_TRANSFER : GROUP_EXTERNAL_TRANSFER,
    {
      refetchQueries: [
        "SpendGroupsFiltered",
        { query: GET_ORGANIZATION_ACCOUNTS, fetchPolicy: "network-only" },
      ],
      fetchPolicy: "network-only",
    }
  );
  const { data: accountsData, loading: loadingAccounts } = useQuery(
    type === "program" ? GET_ORG_BANK_ACCOUNTS : GET_GROUP_BANK_ACCOUNTS,
    { variables: { groupId: activeGroupId } }
  );

  const { data: groups, loading: loadingGroups } = useQuery(
    GET_GROUPS_WITH_NAME_ID,
    {
      fetchPolicy: "network-only",
    }
  );

  const [to, setTo] = useState<SnapSelectMenuOption[]>([]);
  const [fromName, setFromName] = useState("");
  const [fromId, setFromId] = useState("");
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");

  const [from, setFrom] = useState<SnapSelectMenuOption[]>([]);
  const [linkedAccounts, setLinkedAccounts] = useState<SpendBankAccount[]>([]);
  const [depositAccount, setDepositAccount] = useState("");

  //accountId - Plaid
  const [selectedAccountId, setSelectedAccountId] = useState("");
  const [direction, setDirection] = useState("");

  const [externalTo, setExternalTo] = useState("");
  const [externalFrom, setExternalFrom] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [isExternalTransferEnabled, setisExternalTransferEnabled] =
    useState(true);
  const [limits, setLimits] = useState<SpendAccountLimitsResponse>();

  useEffect(() => {
    setTo([]);
    setFrom([]);
    setSelectedTab(0);
    setDirection("");
    setSelectedAccountId("");
  }, []);

  useEffect(() => {
    if (transferError && transferError?.message) {
      setHasErrorObj({ ...hasErrorObj, availableError: true });
      toast.setToastProps({
        title: "Unable to Transfer Funds",
        message: transferError?.message ?? "",
        type: "danger",
      });
      toast.toggleToast();
      setIsActive(true);
    } else {
      let showToast = false;
      if (type === "program") {
        let selectedToItem = to.filter((op) => op.selected);
        let selectFromItem = from.filter((op) => op.selected);
        if (transferData && transferData?.spendOrganizationAccountTransfer) {
          toast.setToastProps({
            message: `${FormatMoney(
              Number(amount) * 100
            )} was Successfully Transfered to ${
              selectedToItem.at(0)?.name
            } from ${selectFromItem.at(0)?.name}`,
            type: "success",
          });
          showToast = true;
        }
        setLimits(programContext?.accountLimits);
      } else {
        let selectedToItem = to.filter((op) => op.selected);
        let selectFromItem = from.filter((op) => op.selected);
        if (transferData && transferData?.spendOrganizationAccountTransfer) {
          toast.setToastProps({
            message: `${FormatMoney(
              Number(amount) * 100
            )} was Successfully Transfered to ${
              selectedToItem.at(0)?.name
            } from ${selectFromItem.at(0)?.name}`,
            type: "success",
          });
          showToast = true;
        }
        if (groupContext?.accountLimits) {
          setLimits(groupContext.accountLimits);
        }
      }
      if (showToast) {
        toast.toggleToast();
        setAmount("");
        setNote("");
        setHasErrorObj({
          toError: false,
          fromError: false,
          noteError: false,
          amountError: false,
          availableError: false,
          macthingError: false,
        });
        initDropdowns();
        transferToggle();
        setIsActive(true);
        window.location.reload();
      }
    }
    // eslint-disable-next-line
  }, [
    loadingTransfer,
    transferData,
    type,
    transferError,
    groupContext?.accountLimits,
    programContext?.accountLimits,
  ]);
  useEffect(() => {
    if (type === "program") {
      if (exAccountData && exAccountData.spendOrganizationExternalTransfer) {
        toast.setToastProps({
          message: `${FormatMoney(
            Number(amount) * 100
          )} was Successfully Transfered to ${externalTo} from ${externalFrom}`,
          type: "success",
        });
        toast.toggleToast();
        setAmount("");
        setNote("");
        initDropdowns();
        setHasErrorObj({
          toError: false,
          fromError: false,
          noteError: false,
          amountError: false,
          availableError: false,
          macthingError: false,
        });
        transferToggle();
        setIsActive(true);
      }
    } else {
      if (exAccountData && exAccountData.spendGroupExternalTransfer) {
        toast.setToastProps({
          message: `${FormatMoney(
            Number(amount) * 100
          )} was Successfully Transfered to ${externalTo} from ${externalFrom}`,
          type: "success",
        });
        toast.toggleToast();
        setAmount("");
        setNote("");
        initDropdowns();
        setHasErrorObj({
          toError: false,
          fromError: false,
          noteError: false,
          amountError: false,
          availableError: false,
          macthingError: false,
        });
        transferToggle();
        setIsActive(true);
      }
    }

    if (exAccountError && exAccountError.message) {
      setHasErrorObj({ ...hasErrorObj, availableError: true });
      toast.setToastProps({
        title: "Unable to Transfer Funds",
        message: exAccountError?.message ?? "",
        type: "danger",
      });
      toast.toggleToast();
      setIsActive(true);
    }

    // eslint-disable-next-line
  }, [loadingExAccountTransfer, exAccountData, exAccountError]);

  useEffect(() => {
    if (type === "program") {
      if (accountsData && accountsData.spendOrganizationBankAccounts) {
        setLinkedAccounts(
          accountsData.spendOrganizationBankAccounts.externalAccounts
        );
      }
    } else {
      if (accountsData && accountsData.spendGroupBankAccounts) {
        setLinkedAccounts(accountsData.spendGroupBankAccounts.externalAccounts);
      }
    }
  }, [accountsData, loadingAccounts, type]);

  const [se503flag, setSe503flag] = useState<boolean | undefined>();

  useEffect(() => {
    if (type === "program" && se503flag) {
      setisExternalTransferEnabled(
        programContext?.getExternalTransferOutEnabled() ?? false
      );
    }
  }, [programContext, type, se503flag]);

  useEffect(() => {
    initDropdowns2();
    // eslint-disable-next-line
  }, [
    programContext,
    groupContext?.activeGroup?.name,
    type,
    settleCharge,
    groups,
    loadingGroups,
  ]);
  const initDropdowns = () => {
    let program: SnapSelectMenuOption = {
      name:
        programContext?.organization?.nickname ||
        programContext?.organization?.legalName ||
        "",
      selected: false,
      value: programContext?.organization?.id ?? "",
    };
    let group: SnapSelectMenuOption[] | undefined = [];
    const createOptions = (group: Maybe<SpendGroup>) => {
      let option: SnapSelectMenuOption = {
        name: group?.name ?? "",
        selected: false,
        value: group?.id ?? "",
      };
      return option;
    };
    if (type === "program") {
      group = groups?.spendGroups?.groups.map(createOptions);
      setFrom([program, ...(group ?? [])]);
      setTo([program, ...(group ?? [])]);
      setDepositAccount(
        programContext?.organization?.nickname ||
          programContext?.organization?.legalName ||
          ""
      );
    } else {
      let groupOp: SpendGroup | undefined = undefined;
      if (userContextRole === "program_admin") {
        group = groupContext?.groups
          ?.filter((acc) => acc.id !== groupContext?.activeGroup?.id)
          .map(createOptions);
        groupOp = groupContext?.groups?.find(
          (acc) => acc.id === groupContext?.activeGroup?.id
        );
      } else {
        group = programContext?.organization?.groups
          ?.filter(
            (acc) =>
              acc?.id !== groupContext?.activeGroup?.id || acc?.isArchived
          )
          .map(createOptions);
        groupOp = programContext?.organization?.groups?.find(
          (acc) => acc?.id === groupContext?.activeGroup?.id
        ) as SpendGroup;
      }
      if (settleCharge && isArchived) {
        setFromName(
          programContext?.organization?.nickname ||
            programContext?.organization?.legalName ||
            ""
        );
        setFromId(programContext?.organization?.id ?? "");
        setTo([createOptions(groupContext?.activeGroup ?? {})]);
      } else if (isArchived) {
        setFromName(groupContext?.activeGroup?.name ?? "");
        setFromId(groupContext?.activeGroup?.id ?? "");
        setTo([program]);
        setDepositAccount(groupContext?.activeGroup?.id ?? "");
      } else {
        setFromName(groupOp?.name ?? "");
        setFromId(groupOp?.id ?? "");
        setTo([program, ...(group ?? [])]);
        setDepositAccount(groupOp?.name ?? "");
      }
    }
  };

  const initDropdowns2 = () => {
    let program: SnapSelectMenuOption = {
      name:
        programContext?.organization?.nickname ||
        programContext?.organization?.legalName ||
        "",
      selected: false,
      value: programContext?.organization?.id ?? "",
    };
    const groupOptions = programContext?.getGroupMenuItems();
    if (type === "program") {
      setTo([program, ...(groupOptions ?? [])]);
      setFrom([program, ...(groupOptions ?? [])]);
      setDepositAccount(
        programContext?.organization?.nickname ||
          programContext?.organization?.legalName ||
          ""
      );
    } else {
      const groupOp = groupOptions?.find(
        (g) => g.value === groupContext?.activeGroup?.id
      );

      if (settleCharge && isArchived) {
        setFromName(
          programContext?.organization?.nickname ||
            programContext?.organization?.legalName ||
            ""
        );
        setFromId(programContext?.organization?.id ?? "");
        setTo([groupOp!]);
      } else if (isArchived) {
        setFromName(groupContext?.activeGroup?.name ?? "");
        setFromId(groupContext?.activeGroup?.id ?? "");
        setTo([program]);
        setDepositAccount(groupContext?.activeGroup?.id ?? "");
      } else {
        setFromName(groupOp?.name ?? "");
        setFromId(groupOp?.value ?? "");
        setTo([program, ...(groupOptions ?? [])]);
        setDepositAccount(groupOp?.name ?? "");
      }
    }
  };

  const handleTransfer = () => {
    let selectedToItem = to.filter((op) => op.selected);
    let selectFromItem = from.filter((op) => op.selected);
    if (selectedToItem.length === 0) {
      hasErrorObj.toError = true;
      setHasErrorObj({ ...hasErrorObj, toError: true });
    }
    if (selectFromItem.length === 0 && type === "program") {
      hasErrorObj.fromError = true;
      setHasErrorObj({ ...hasErrorObj, fromError: true });
    }
    if (amount === "0.00" || amount === "") {
      hasErrorObj.amountError = true;
      setHasErrorObj({ ...hasErrorObj, amountError: true });
    }
    if (note === "") {
      hasErrorObj.noteError = true;
      setHasErrorObj({ ...hasErrorObj, noteError: true });
    }
    let { amountError, fromError, noteError, toError } = hasErrorObj;
    if (amountError || fromError || noteError || toError) {
      toast.setToastProps({
        message: "Please fill in all the input fields",
        type: "danger",
      });
      toast.toggleToast();
      setIsActive(true);
    } else {
      if (selectedToItem.at(0)?.name === selectFromItem.at(0)?.name) {
        toast.setToastProps({
          type: "danger",
          message: "To and From can not be the same accounts",
        });
        toast.toggleToast();
        setHasErrorObj({ ...hasErrorObj, macthingError: true });
        setIsActive(true);
      } else if (note.length >= 81) {
        toast.setToastProps({
          type: "danger",
          message: "Note can not be longer than 80 characters",
        });
        toast.toggleToast();
        setIsActive(true);
      } else {
        if (settleCharge && unitAmount !== undefined && unitAmount !== null) {
          if (unitAmount + Number(amount.replace(",", "")) * 100 > 0) {
            toast.setToastProps({
              message: "Settle charge cannot exceed 0",
              type: "danger",
            });
            toast.toggleToast();
            setIsActive(true);
          } else {
            accountTransfer({
              variables: {
                input: {
                  amount: Math.round(Number(amount.replace(",", "")) * 100),
                  description: note,
                  toGroupIdOrOrgId: selectedToItem[0].value,
                  fromGroupIdOrOrgId:
                    type === "program" ? selectFromItem[0].value : fromId,
                },
              },
              fetchPolicy: "network-only",
            });
          }
        } else {
          accountTransfer({
            variables: {
              input: {
                amount: Math.round(Number(amount.replace(",", "")) * 100),
                description: note,
                toGroupIdOrOrgId: selectedToItem[0].value,
                fromGroupIdOrOrgId:
                  type === "program" ? selectFromItem[0].value : fromId,
              },
            },
            fetchPolicy: "network-only",
          });
        }
      }
    }
  };

  const handleExternalTransfer = () => {
    if (amount === "0.00" || amount === "") {
      setHasErrorObj({ ...hasErrorObj, amountError: true });
      hasErrorObj.amountError = true;
    }
    if (note === "") {
      setHasErrorObj({ ...hasErrorObj, noteError: true });
      hasErrorObj.noteError = true;
    }
    let { amountError, noteError } = hasErrorObj;

    if (amountError || noteError) {
      toast.setToastProps({
        message: "Please fill in all the input fields",
        type: "danger",
      });
      toast.toggleToast();
      setIsActive(true);
    } else {
      if (note.length >= 81) {
        toast.setToastProps({
          type: "danger",
          message: "Note must not be longer than 80 character",
        });
        toast.toggleToast();
        setIsActive(true);
      } else {
        let input =
          type === "program"
            ? {
                accountId: selectedAccountId,
                amount: Math.round(Number(amount.replace(",", "")) * 100),
                direction: direction,
                note: note,
              }
            : {
                accountId: selectedAccountId,
                amount: Math.round(Number(amount.replace(",", "")) * 100),
                direction: direction,
                id: activeGroupId,
                note: note,
              };
        extAccountTransfer({
          variables: {
            input,
          },
        });
      }
    }
  };

  const handleSelection = (type: string, options: SnapSelectMenuOption[]) => {
    if (type === "to") {
      setTo(options);
      setHasErrorObj({ ...hasErrorObj, toError: false, macthingError: false });
    }
    if (type === "from") {
      setFrom(options);
      setHasErrorObj({
        ...hasErrorObj,
        fromError: false,
        macthingError: false,
      });
    }
  };

  return (
    <CustomModal
      isOpen={isTransferOpen}
      toggle={() => {
        transferToggle();
        initDropdowns();
        setAmount("");
        setNote("");
        setHasErrorObj({
          toError: false,
          fromError: false,
          noteError: false,
          amountError: false,
          availableError: false,
          macthingError: false,
        });
        setIsActive(true);
      }}
      title={"Transfer"}
      btn1={{
        text: "Submit",
        btnStyle: "primary",
        onClick: () => {
          setIsActive(false);
          if (selectedTab === 0) {
            handleTransfer();
          } else {
            handleExternalTransfer();
          }
        },
        btnState: isActive ? BtnState.BASE : BtnState.DISABLED,
      }}
      btn2={{
        text: "Cancel",
        btnStyle: "tertiary",
        onClick: () => {
          transferToggle();
          initDropdowns();
          setAmount("");
          setNote("");
          setHasErrorObj({
            toError: false,
            fromError: false,
            noteError: false,
            amountError: false,
            availableError: false,
            macthingError: false,
          });
        },
      }}
      customStyle={`lg:w-[1082px] lg:mt-[20px] max-h-screen overflow-y-scroll`}
    >
      <>
        {!isArchived && (
          <div className="bg-white grid grid-cols-2 text-center lg:flex lg:justify-start border-b-2">
            {tabOptions.map((tab, idx) => {
              return (
                canExternalTransfer &&
                isExternalTransferEnabled && (
                  <p
                    key={idx}
                    className={`py-3 text-sm font-medium cursor-pointer ml-0 lg:ml-8 ${
                      tabOptions[selectedTab] === tab
                        ? activeTabStyle
                        : "text-gray-500"
                    }`}
                    onClick={() => {
                      setSelectedTab(idx);
                      setNote("");
                      setAmount("");
                      setHasErrorObj({
                        toError: false,
                        fromError: false,
                        noteError: false,
                        amountError: false,
                        availableError: false,
                        macthingError: false,
                      });
                      initDropdowns();
                    }}
                  >
                    {tab} <span className="hidden lg:inline">Transfer</span>
                  </p>
                )
              );
            })}
          </div>
        )}
        {
          {
            0:
              type === "program" ? (
                <InternalProgram
                  to={to}
                  isLoading={!groupContext?.loadedGroup}
                  from={from}
                  handleSelection={handleSelection}
                  setAmount={setAmount}
                  setNote={setNote}
                  amount={amount}
                  hasErrorObj={hasErrorObj}
                  setHasErrorObj={setHasErrorObj}
                />
              ) : (
                <InternalGroup
                  isLoading={!groupContext?.loadedGroup}
                  fromName={fromName}
                  to={to}
                  handleSelection={handleSelection}
                  amount={amount}
                  setAmount={setAmount}
                  setNote={setNote}
                  hasErrorsObj={hasErrorObj}
                  setHasErrorObj={setHasErrorObj}
                />
              ),
            1: canExternalTransfer && (
              <ExternalTransfer
                hasLinkedBankAccount={hasLinkedAccount}
                openPlaid={openPlaid}
                linkedAccounts={linkedAccounts}
                despotAccount={depositAccount}
                setAmount={setAmount}
                setNote={setNote}
                setSelectedAccountId={setSelectedAccountId}
                setDirection={setDirection}
                amount={amount}
                hasErrorsObj={hasErrorObj}
                setHasErrorObj={setHasErrorObj}
                setExternalTo={setExternalTo}
                setExternalFrom={setExternalFrom}
                limits={limits}
              />
            ),
          }[selectedTab]
        }
      </>
    </CustomModal>
  );
}

export default Transfer;
