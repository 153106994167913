import { V3 as api } from '@snap-mobile/payments-widget-client';
import { useCallback } from 'react';

import {
  CreatePaymentIntentData,
  User,
} from '@snap-mobile/payments-widget-utils';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { usePaymentsWidgetContext } from '../context';
import { StripeExpressCheckoutElementConfirmEvent } from '@stripe/stripe-js';

export const usePaymentsConfirmElement = () => {
  const {
    setError,
    setProcessing,
    paymentData,
    stripeEnvironment,
    paymentConfiguration,
    customer: _customer,
    invalidated,
    setupFutureUsage,
  } = usePaymentsWidgetContext();
  const stripe = useStripe();
  const elements = useElements();

  const {
    // payment info related
    description,
    externalPaymentId,
    idempotencyKey,
    metadata,
    snapAmount,
    // totalAmount,
    statementDescriptorSuffix,

    // payment method related
    permittedPaymentMethods,
    destination,
    finalDestination,

    // payment setup related
    automatic,
    returnUrl,
    refresh,
  } = paymentData;
  const confirmPayment = useCallback(
    async (
      customer?: User,
      expressEvent?: StripeExpressCheckoutElementConfirmEvent
    ) => {
      if (invalidated) {
        console.warn('data props is invalid');
        return null;
      }
      if (!stripe) {
        setError({ type: 'processing', message: 'Stripe is not assigned' });
        console.error('stripe is not assigned');
        return null;
      }
      const cus = customer || _customer;
      if (!cus) {
        setError({ type: 'processing', message: 'customer is not assigned' });
        console.error('customer is not assigned');
        return;
      }
      if (!elements) {
        setError({ type: 'processing', message: 'elements is not assigned' });
        console.error('elements is not assigned');
        return null;
      }
      setProcessing(true);
      const submitExpressEvent = () =>
        expressEvent?.paymentFailed({ reason: 'fail' });

      try {
        const data: CreatePaymentIntentData = {
          automaticPaymentMethods: { enabled: automatic ?? true },
          customer: cus!.customerId,
          description,
          destination: paymentConfiguration?.stripeAccountId ?? destination,
          externalPaymentId,
          finalDestination,
          idempotencyKey: idempotencyKey || '',
          metadata: metadata || {},
          permittedPaymentMethods,
          statementDescriptorSuffix: statementDescriptorSuffix,
          setupFutureUsage,
          snapAmount: snapAmount,
          totalAmount: paymentData.totalAmount,
        };

        const pi = await api.createPaymentIntent(data, { stripeEnvironment });
        if (pi.message) {
          setError({
            type: 'processing',
            message: pi.message ?? 'Error creating payment intent.',
          });
          setProcessing(false);
          return;
        }
        const redirect = refresh === false ? 'if_required' : undefined;
        const confirmData = {
          elements: elements,
          clientSecret: pi.client_secret,
          confirmParams: { return_url: returnUrl ?? window.location.href },
          redirect: redirect as any,
        };
        const { error, paymentIntent } = await stripe.confirmPayment(
          confirmData
        );
        if (error) {
          setError({
            type: 'processing',
            message: error?.message || 'Error confirming payment.',
            data: {
              step: 'confirm_payment',
              code: error.code,
              type: error.type,
            },
          });
          setProcessing(false);
          return;
        }
        setProcessing(false);
        return paymentIntent;
      } catch (e: any) {
        submitExpressEvent();
        setProcessing(false);
        setError({
          type: 'processing',
          message: e?.message || e || 'Error processing payment.',
          data: {
            code: e.code,
            type: e.type,
          },
        });
      }
    },
    [_customer, stripe, paymentData]
  );

  return {
    confirmPayment,
  };
};
