import { PaymentMethod } from '@snap-mobile/payments-widget-utils';
import { usePaymentsCustomer } from './usePaymentsCustomer';
import { usePaymentsConfirm } from './usePaymentsConfirm';
import { usePaymentsWidgetContext } from '../context';
import { PaymentIntent } from '@stripe/stripe-js';

export type useExistingPaymentsMethodSubmitProps = {
  paymentMethod: PaymentMethod;
  onSuccess?: (data: PaymentIntent) => void | Promise<void>;
};
export const useExistingPaymentsMethodSubmit = (props: useExistingPaymentsMethodSubmitProps) => {
  const { paymentMethod, onSuccess } = props;
  const { createCustomer } = usePaymentsCustomer();
  const { confirmPayment } = usePaymentsConfirm(paymentMethod);
  const { setProcessing } = usePaymentsWidgetContext();

  const onSubmit = async () => {
    setProcessing(true);
    const cus = await createCustomer();
    const res = await confirmPayment(cus);
    if (res) {
      onSuccess?.(res);
    }
    setProcessing(false);
  };

  return { onSubmit };
};
