import GroupContext from "context/group-context";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { useContext } from "react";
import { SnapBadge, SnapCheckboxButton, SnapSelectMenu, SnapTable } from "suit";
import { SelectedInvoice } from "types/invoice";
import { calcTotalPlusFee } from "./make-payment-helper";

type InvoiceTableViewProps = {
  list: SelectedInvoice[];
  isReadonly: boolean;
  handleCheckId: (e: SelectedInvoice | null, checked?: any) => void;
  handleOptStatus: (status: boolean, invoice: SelectedInvoice) => void;
  isAuthorizeFlow?: boolean;
};

export const InvoiceTableView = ({
  list,
  isReadonly = false,
  handleCheckId,
  handleOptStatus,
  isAuthorizeFlow,
}: InvoiceTableViewProps) => {
  const activeGroup = useContext(GroupContext)?.activeGroup;
  const readOnly = isReadonly ? list.filter((x) => x.selected) : list;
  return (
    <SnapTable>
      <table className="ui table table-fixed">
        <thead>
          <tr>
            <th
              className={`w-[24px] ${
                (isReadonly || isAuthorizeFlow) && "hidden"
              }`}
            >
              <SnapCheckboxButton
                checked={
                  list.length === list.filter((inv) => inv.selected).length
                }
                onClick={(e) =>
                  handleCheckId(
                    null,
                    (e.target as HTMLSnapCheckboxButtonElement).checked
                  )
                }
              />
            </th>
            <th className="w-4/12">Description</th>
            <th className="text-center">Due Date</th>
            <th className="text-center">Pay By Bank</th>
            <th className="text-center">Pay By Card*</th>
            <th className="text-center">Optional</th>
          </tr>
        </thead>
        <tbody>
          {readOnly &&
            readOnly.map((invoice, index) => {
              return (
                <tr key={`${invoice.id}-${index}`} className="h-8">
                  <td
                    className={`${
                      (isReadonly || isAuthorizeFlow) && "hidden"
                    } py-4`}
                  >
                    <SnapCheckboxButton
                      disabled={!invoice.optedIn && !!invoice.isOptional}
                      value={`${index}`}
                      checked={invoice.selected}
                      onClick={(e) =>
                        handleCheckId(
                          invoice,
                          (e.target as HTMLSnapCheckboxButtonElement).checked
                        )
                      }
                    />
                  </td>
                  <td>{invoice.description}</td>
                  <td className="text-center">
                    {FormatDate(invoice.dueDate!, DateFormatSupported.Words)}
                  </td>
                  <td className="text-center">
                    {FormatMoney(
                      calcTotalPlusFee(
                        invoice.balanceDue ?? 0,
                        activeGroup?.organizationFees?.achPercent ?? 0,
                        activeGroup?.organizationFees?.achBaseFee ?? 0
                      )
                    )}
                  </td>
                  <td className="text-center">
                    {FormatMoney(
                      calcTotalPlusFee(
                        invoice.balanceDue ?? 0,
                        activeGroup?.organizationFees?.cardPercent ?? 0,
                        activeGroup?.organizationFees?.cardBaseFee ?? 0
                      )
                    )}
                  </td>
                  <td align="center">
                    {invoice.isOptional && !isReadonly ? (
                      <SnapSelectMenu
                        className="w-24"
                        selectMenuOptions={[
                          {
                            name: "Opt-In",
                            selected: invoice.optedIn ?? false,
                          },
                          {
                            name: "Opt-Out",
                            selected: !invoice.optedIn,
                          },
                        ]}
                        onSnap-select-menu-updated={(e) => {
                          let status = e.detail.find(
                            (option) => option.selected
                          )?.name;
                          handleOptStatus(status === "Opt-In", invoice);
                        }}
                      />
                    ) : (
                      <SnapBadge className="flex justify-center">
                        {invoice.isOptional && invoice.optedIn
                          ? "Opt-In"
                          : "Required"}
                      </SnapBadge>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </SnapTable>
  );
};
