import { getBadgeColor } from "helpers/status-color";
import { SnapBadge } from "suit";
import Table from "./invoice-table";
import { SpendInvoice } from "graphql/generated";
import { GET_INVOICES_WITH_FILTER } from "graphql/queries/invoices";
import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { StepProps } from ".";
import { FormatMoney } from "helpers/format-money";
import Cards from "./invoice-cards";

function StepFour({ selectedDetails, setSelectedDetails }: StepProps) {
  let titleStyles = "text-xs font-semibold text-gray-500";
  const [getInvoices, { loading, data }] = useLazyQuery(
    GET_INVOICES_WITH_FILTER,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (selectedDetails) {
      const { season, team, participant, transaction } = selectedDetails;
      if (season && team && participant && transaction) {
        getInvoices({
          variables: {
            filterBy: "groupRosterId",
            filterValue: participant.id,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDetails.participant?.id]);

  useEffect(() => {
    if (!loading && data && data.spendInvoices) {
      const invoices = data.spendInvoices.invoices.filter(
        (invoice: SpendInvoice) => !invoice.paid
      );
      setSelectedDetails({
        ...selectedDetails,
        distribution: [
          ...invoices.map((invoice: SpendInvoice) => {
            const amount =
              selectedDetails.distribution?.find(
                (d) => d.invoice.id === invoice.id
              )?.amount ?? 0;
            return { invoice, amount: amount };
          }),
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);
  return (
    <div className="h-[100%] overflow-y-scroll">
      <p>Apply Payment</p>
      <div className="lg:flex hidden border rounded-lg mt-4 p-4">
        <div className=" mr-20">
          <p className={titleStyles}>GROUP</p>
          <p className="text-sm">{selectedDetails.team?.name}</p>
        </div>
        <div className="mr-20">
          <p className={titleStyles}>SEASON</p>
          <p className="text-sm">{selectedDetails.season?.name}</p>
        </div>
        <div>
          <p className={titleStyles}>PARTICIPANT</p>
          <div className="flex">
            <p className="text-sm mr-2">
              {selectedDetails.participant?.roster?.name}
            </p>
            <SnapBadge
              color={getBadgeColor(
                !!selectedDetails.participant?.joinedAt
                  ? "Signed Up"
                  : "Not Signed Up"
              )}
            >
              {!!selectedDetails.participant?.joinedAt
                ? "Signed Up"
                : "Not Signed Up"}
            </SnapBadge>
          </div>
          <p className="text-xs text-gray-500">
            {selectedDetails.participant?.roster?.email}
          </p>
        </div>
      </div>
      <p className="text-sm text-gray-500 mt-4">
        The{" "}
        <span className="font-bold text-gray-800">
          {FormatMoney(
            (selectedDetails.transaction?.amount ?? 0) -
              (selectedDetails.transaction?.totalApplied ?? 0)
          )}
        </span>{" "}
        payment will be applied as indicated below; edit amounts as necessary. A
        note describing this transaction will be added to all relevant invoice
        items.
      </p>
      {selectedDetails.distribution && (
        <Table
          selectedDetails={selectedDetails}
          setSelectedDetails={setSelectedDetails}
          className={"hidden lg:table"}
        />
      )}
      <Cards
        selectedDetails={selectedDetails}
        setSelectedDetails={setSelectedDetails}
        className={"lg:hidden"}
      />
    </div>
  );
}

export default StepFour;
