import { PaymentElement, useElements } from '@stripe/react-stripe-js';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  StripePaymentElementChangeEvent,
  PaymentIntent,
  StripePaymentElement,
  StripeError,
} from '@stripe/stripe-js';
import { usePaymentsWidgetContext } from '../context';
import { usePaymentsConfirmElement } from '../hooks/usePaymentsConfirmElement';
import { usePaymentsCustomer } from '../hooks/usePaymentsCustomer';
import { ExternalPaypalPaymentMethod } from './ExternalPaypalPaymentMethod';
import { SubmitButton } from './SubmitButton';

export type AllowedChildren = React.ReactElement<
  typeof SubmitButton | typeof ExternalPaypalPaymentMethod | React.ReactElement
>;

export type PaymentMethodProps = {
  children: AllowedChildren[] | AllowedChildren;
  onSuccess?: (data: PaymentIntent) => void | Promise<void>;
  onReady?: (data: StripePaymentElement) => void;
  onLoadError?: (event: { elementType: 'payment'; error: StripeError }) => void;
  onChangePaymentType?: (event: StripePaymentElementChangeEvent) => void;
};

export type PaymentMethodRef = {
  validatePayment: () => Promise<boolean>;
  submit: () => void;
};

/**
 * Props for `PaymentMethod` Component
 * @prop {AllowedChildren[] | AllowedChildren} children - Child components that can be either a single
 *                                                         element or an array of elements. The children are
 *                                                         restricted to be of type `SubmitButton` or
 *                                                         `ExternalPaypalPaymentMethod`.
 * @prop {(data: PaymentIntent) => void | Promise<void>} onSuccess - Optional callback function invoked upon
 *                                                                   successful payment processing. Receives a
 *                                                                   `PaymentIntent` as its argument.
 * @prop {(data: StripePaymentElement) => void} onReady - Optional callback function invoked when the payment form is ready.
 * @prop {(event: { elementType: 'payment'; error: StripeError }) => void} onLoadError - Optional callback function invoked when there is an error loading the payment element.
 *
 * Ref Methods:
 * @method validatePayment - Asynchronously validates the current payment method. Returns a `Promise` that
 *                           resolves to a boolean indicating the validity of the payment method through submitting elements.
 * @method submit - Triggers the payment submission process.
 *
 * This component integrates various Stripe functionalities to process payments. It is designed to work with
 * Stripe's React elements and custom hooks for payment processing. The component is capable of handling
 * payment submission and validation, and it can dynamically include custom child components like `SubmitButton`
 * or `ExternalPaypalPaymentMethod`.
 *
 * The component uses `usePaymentsWidgetContext` for accessing and manipulating payment processing states
 * and errors. It uses `usePaymentsConfirmElement` and `usePaymentsCustomer` for handling payment confirmations
 * and customer creation. The `PaymentElement` from Stripe is used for capturing payment details, and its change
 * events are handled for updating selected payment methods.
 *
 * Usage:
 * <PaymentsWidgetProvider {...props}>
 * <PaymentMethod
 *   onSuccess={(data) => console.log('Payment Successful', data)}
 * >
 *   <ExternalPaypalPaymentMethod />
 *   <SubmitButton />
 * </PaymentMethod>
 * </PaymentsWidgetProvider>
 */
export const PaymentMethod = forwardRef<PaymentMethodRef, PaymentMethodProps>(
  ({ children, onSuccess, onReady, onLoadError, onChangePaymentType }: PaymentMethodProps, ref) => {
    const {
      elementOptions,
      setError,
      valid,
      setSelectedPaymentMethod,
      setProcessing,
    } = usePaymentsWidgetContext();

    const elements = useElements();
    const [isReady, setIsReady] = React.useState(false);
    const { confirmPayment } = usePaymentsConfirmElement();
    const { createCustomer } = usePaymentsCustomer();

    const elementSubmit = async () => {
      if (!elements) {
        console.log('elements not existed');
        return null;
      }
      const res = await elements?.submit();
      if (res.error) {
        setError({
          type: 'validation',
          message: (res.error as any)?.message || 'Failed to submit',
        });
      }

      return res;
    };

    const onSubmit = async (e?: React.SyntheticEvent) => {
      e?.preventDefault();
      if (!valid) {
        setError({
          type: 'validation',
          message: 'Invalid',
        });
        return null;
      }
      setProcessing(true);
      await elementSubmit();
      const cus = await createCustomer();
      if (!cus) return null;

      const paymentConfirmResponse = await confirmPayment(cus);
      if (paymentConfirmResponse) {
        onSuccess?.(paymentConfirmResponse);
      }
      setProcessing(false);
    };

    const formRef = useRef<HTMLFormElement | null>(null);

    useImperativeHandle(ref, () => ({
      validatePayment: async () => {
        const res = await elementSubmit();
        return !res?.error && valid;
      },
      submit() {
        onSubmit();
      },
    }));

    const onChange = (e: StripePaymentElementChangeEvent) => {
      setSelectedPaymentMethod(e.value.type);
      !!onChangePaymentType && onChangePaymentType(e);
    };

    const getChildrenValidation = useCallback(() => {
      const encounteredTypes = new Set<Function>();

      React.Children.forEach(children, (child) => {
        if (
          child.type !== SubmitButton &&
          child.type !== ExternalPaypalPaymentMethod
        ) {
          throw new Error(
            'Invalid child component. Only SubmitButton and ExternalPaypalPaymentMethod are allowed.'
          );
        }
        if (encounteredTypes.has(child.type)) {
          throw new Error(
            `Duplicate child component of type ${child.type.name} is not allowed.`
          );
        }

        encounteredTypes.add(child.type);
      });
    }, [children]);

    useEffect(() => {
      getChildrenValidation();
    }, [children]);

    const onPaymentFormReady = (e: StripePaymentElement) => {
      setIsReady(true);
      onReady?.(e);
    };

    const handleOnLoadError = (e: {
      elementType: 'payment';
      error: StripeError;
    }) => {
      onLoadError?.(e);
      setError({
        type: 'initiating',
        message:
          e.error.message || 'Failed to load payment form, please try again',
      });
    };

    return (
      <div className="new-payment-method">
        <form onSubmit={onSubmit} ref={formRef}>
          <PaymentElement
            onLoadError={handleOnLoadError}
            onChange={onChange}
            options={elementOptions}
            onReady={onPaymentFormReady}
          />
          {isReady ? children : null}
        </form>
      </div>
    );
  }
);
