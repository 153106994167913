import { gql } from "@apollo/client";

export const GET_REPORTING_INVOICES = gql`
  query SpendPaginatedInvoices(
    $pagination: SpendPaginationInput
    $filters: [SpendInvoiceFilter]
    $sort: SpendSortInput
  ) {
    spendPaginatedInvoices(
      pagination: $pagination
      filters: $filters
      sort: $sort
    ) {
      hasNext
      count
      invoices {
        amount
        description
        discountAmount
        groupRoster {
          roster {
            id
            name
            email
          }
          season {
            id
            name
          }
          group {
            id
            name
          }
          invite {
            status
          }
        }
        balanceDue
        dueDate
        paid
        createdAt
        status
        isRefunded
      }
    }
  }
`;

export const INVOICES_EXPORT = gql`
  query SpendInvoicesExport($limit: Int, $filters: [SpendInvoiceFilter]) {
    spendInvoicesExport(limit: $limit, filters: $filters) {
      content
      fileName
    }
  }
`;
export const INVOICES_EXPORT_V2 = gql`
  query SpendInvoicesExportV2($filters: [SpendInvoiceFilter]) {
    spendInvoicesExportV2(filters: $filters) {
      content
      fileName
    }
  }
`;
export const GET_INVOICES_WITH_FILTER = gql`
  query Invoices($filterBy: InvoiceFilterEnum, $filterValue: String) {
    spendInvoices(filterBy: $filterBy, filterValue: $filterValue) {
      invoices {
        id
        amount
        paid
        paidDate
        dueDate
        balanceDue
        description
        optedIn
        isOptional
        groupRosterId
        budgetItemId
        lastNotifyId
        lastNotifyDate
        notificationAttempts
        paymentScheduleInvoiceId
        createdAt
        updatedAt
        isReconciled
        discountAmount
        paymentMethodId
        paymentMethodSource
        isAutoPayAuthorized
        groupRoster {
          id
          roster {
            id
            name
          }
          group {
            id
            name
          }
          season {
            id
            name
          }
        }
        budgetItem {
          description
          id
        }
        reconciledTransactions {
          id
          transactionId
          amount
        }
        transaction {
          id
          externalId
          invoiceId
          source
        }
      }
      count
    }
  }
`;

export const GET_USER_PAST_DUE_INVOICES = gql`
  query GetUsersPastDueInvoices {
    spendRosters {
      count
      rosters {
        id
        email
        name
        groupRosters {
          season {
            name
          }
          group {
            name
            id
          }
          invoices {
            id
            dueDate
            balanceDue
            amount
            paid
            description
            status
            isArchived
          }
          invite {
            firstName
            lastName
            email
            status
          }
        }
      }
    }
  }
`;

export const GET_INVOICES_WITH_PAYMENT_METHOD = gql`
  query getInvoicesWithPaymentId(
    $filterValue: String
    $filterBy: GroupRosterFilterEnum
  ) {
    spendGroupRosters(filterValue: $filterValue, filterBy: $filterBy) {
      count
      groupRosters {
        invoices {
          paymentMethodId
          id
          paid
          status
        }
      }
    }
  }
`;

export const PARENT_SIGNUP_INVOICES = gql`
  query SpendInvoices(
    $filterValue: String
    $filterBy: InvoiceFilterEnum
    $parentSignUp: Boolean
  ) {
    spendInvoices(
      filterValue: $filterValue
      filterBy: $filterBy
      parentSignUp: $parentSignUp
    ) {
      count
      invoices {
        description
        amount
        optedIn
        isOptional
        groupRosterId
        dueDate
        balanceDue
        id
        isArchived
        paid
        creditMemos {
          id
          title
          note
          dateToApply
          creditApplied
          creditAmount
          isArchived
        }
      }
    }
  }
`;

export const EMAIL_INVOICE_HISTORY = gql`
  query SpendInvoiceHistory($input: SpendInvoiceHistoryInput) {
    spendInvoiceHistory(input: $input) {
      success
    }
  }
`;

export const INVOICE_BY_ID = gql`
  query SpendInvoiceById($id: String!) {
    spendInvoiceById(id: $id) {
      amount
      balanceDue
      budgetItemId
      createdAt
      description
      dueDate
      feesAmount
      groupRosterId
      id
      isArchived
      isAutoPayAuthorized
      isOptional
      isReconciled
      isRefunded
      lastNotifyId
      lastNotifyDate
      note
      notificationAttempts
      optedIn
      paid
      paidDate
      paymentMethodId
      paymentMethodSource
      paymentScheduleInvoiceId
      refundDate
      status
      updatedAt
      transactionSettledDate
      transactionStatus
      transactionRejectReason
      groupRoster {
        group {
          name
        }
        id
        roster {
          name
        }
        seasonId
        season {
          id
          name
        }
      }
    }
  }
`;

export const INVOICE_AUTOPAY = gql`
  query SpendPaginatedInvoicesForAutoPay {
    spendPaginatedInvoices {
      invoices {
        isAutoPayAuthorized
        groupRosterId
        paid
        balanceDue
        authorizedAt
        optedOutAt
        optedIn
        isOptional
        groupRoster {
          joinedAt
          roster {
            name
          }
          settings {
            isAutoPayAuthorized
          }
          invite {
            status
            isUser
          }
        }
      }
    }
  }
`;

export const GUARDIAN_INVOICES_V2 = gql`
  query SpendGuardianInvoicesV2 {
    spendGuardianInvoicesV2 {
      invoices {
        isAutoPayAuthorized
        groupRosterId
        paid
        balanceDue
        authorizedAt
        optedOutAt
        optedIn
        isOptional
        groupRoster {
          joinedAt
          roster {
            name
          }
          settings {
            isAutoPayAuthorized
          }
          invite {
            status
            isUser
            userId
          }
          userId
        }
      }
      count
      hasNext
    }
  }
`;

export const GET_GUARDIAN_INVOICES = gql`
  query SpendGuardianInvoices($filters: [SpendGuardianInvoiceFilter]) {
    spendGuardianInvoices(filters: $filters) {
      count
      invoices {
        amount
        authorizedAt
        balanceDue
        description
        discountAmount
        dueDate
        id
        isAutoPayAuthorized
        isAutoPayStopped
        isOptional
        isRefunded
        optedIn
        paid
        paidDate
        paymentMethodId
        paymentMethodSource
        refundDate
        status
        rosterId
        rosterName
        groupName
        seasonName
        groupId
        groupRosterId
        seasonId
        optedOutAt
        isArchived
        note
        creditMemos {
          id
          title
          note
          dateToApply
          creditApplied
          creditAmount
          isArchived
        }
      }
    }
  }
`;

export const GET_USER_PAST_DUE_INVOICES_2 = gql`
  query SpendPastDueInvoices(
    $pagination: SpendPaginationInput
    $interval: SpendPastDueInterval
    $sort: SpendPastDueSort
  ) {
    spendPastDueInvoices(
      pagination: $pagination
      interval: $interval
      sort: $sort
    ) {
      count
      invoices {
        id
        groupName
        email
        name
        groupId
        status
        rosterId
        amount
        seasonName
        seasonId
        description
        dueDate
        guardianStatus
        discountAmount
        creditMemos {
          creditApplied
        }
      }
    }
  }
`;
