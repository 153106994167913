import { FullStory } from "@fullstory/browser";
import DisplayContext from "context/display-context";
import GroupContext from "context/group-context";
import ProgramContext from "context/program-context";
import SeasonContext from "context/season-context";
import SplitIOContext from "context/splitio-context";
import ToastContext from "context/toast-context";
import { getAuthTokenInfo, removeAuthToken } from "helpers/get-auth-token-data";
import useDisplay from "hooks/use-display";
import useGroup from "hooks/use-group";
import useModal from "hooks/use-modal";
import useProgram from "hooks/use-program";
import useSeason from "hooks/use-season";
import useSplitIO from "hooks/use-splitio";
import useToast from "hooks/use-toast";
import AppicationStatus from "pages/application-status";
import UnsavedChanges from "pages/display-page/unsavedChanges";
import DisplayInviteFlow from "pages/parent-sign-up/display-flow";
import DisplaySignupFlow from "pages/parent-sign-up/display-sign-up-flow";
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "shared-components/header/Header";
import MainNavigation from "shared-components/main-navigation/MainNavigation";
import Deposit from "shared-components/banking/deposit";
import Authorization from "shared-components/fixed-modal/authorization";
import FixedModal from "shared-components/fixed-modal/fixed-modal";
import Footer from "shared-components/footer";
import { SnapLoader } from "shared-components/snap-loader";
import "./App.css";
import UserContext from "./context/user-context";
import { useApiStatusQuery } from "./graphql/generated";
import { redirectToSso } from "./helpers/sso";
import useUser, { ActiveUser } from "./hooks/use-user";
import usePathToNav from "./hooks/use-path-to-nav";
import UnAuthorized from "pages/unauthorized-user";
import ExpiredInvite from "pages/expired-invite";
import ApplicationForm from "pages/application-form";

function App({ updateRoutes }: { updateRoutes?: (user: ActiveUser) => void }) {
  const location = useLocation();

  const user = useUser();
  const program = useProgram(user);
  const group = useGroup(user);
  const season = useSeason();
  const display = useDisplay();
  const toast = useToast();
  const splits = useSplitIO(program.organization?.id, user.getUserId());

  const { data: apiStatus } = useApiStatusQuery();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [textEnabled, setTextEnabled] = useState(false);

  const [isUserReady, setIsUserReady] = useState(false);

  const [userHasApplicationInProgress, setUserHasApplicationInProgress] =
    useState(false);
  const [userHasExpiredInvite, setUserHasExpiredInvite] = useState(false);
  const [userHasRole, setHasUserRole] = useState(false);
  const [userHasCompletedApplication, setUserHasCompletedApplication] =
    useState(false);
  const [userUnAuthorized, setUserUnAuthorized] = useState(false);
  const [parentHasInviteInProgress, setParentHasInviteInProgress] =
    useState(false);
  const [parentSignup, setParentSignup] = useState(false);
  const { isOpen: isSignoutOpen, toggle: signoutToggle } = useModal();
  const { pathToNav } = usePathToNav();
  const { isOpen, toggle } = useModal();

  useEffect(() => {
    setUserHasApplicationInProgress(location.pathname.includes("/application"));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTextEnabled(true);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (user._session) {
      let { token, tokenExpires, hex } = getAuthTokenInfo();
      if (token) {
        const hash = btoa(user._session.userId ?? "");
        if (
          new Date().getTime() < new Date(tokenExpires).getTime() &&
          hex === hash
        ) {
          setIsAuthorized(true);
          setIsLoading(false);
        } else {
          removeAuthToken();
          setIsAuthorized(false);
        }
      } else {
        setIsAuthorized(false);
      }
      setIsUserReady(true);
      FullStory("setProperties", {
        type: "page",
        properties: {
          platform: "spend",
          user: user._session?.userId,
          group: group.activeGroup?.id,
          program: program.organization?.id,
        },
      });
    } else {
      if (user._user?.me === undefined || user._session === undefined) {
        setUserUnAuthorized(false);
        setIsAuthorized(false);
        return;
      }

      const timer = setTimeout(() => {
        redirectToSso();
      }, 500);
      return () => clearTimeout(timer);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user._session, user._user?.me]);

  useEffect(() => {
    //IF INVITE IS EXPIRED
    setUserHasExpiredInvite(
      user._session?.status === "expired" && !user._session.isDismissed
    );
    //IF USER HAS A ROLE
    setHasUserRole(user._session?.role ? true : false);
    //IF THE USER DOES NOT HAVE A ROLE - ORG APP IS NOT APPROVED
    // BUT IF THEY HAVE A MESSAGE - THEY HAVE COMPLETED THE ORG APPLICATION FORM
    //SEE ORG COMPLETED FORM TO SEE STATUS OF FORM
    setUserHasCompletedApplication(
      user._session?.role == null && user._session?.message != null
    );

    //IF THE USER DOESNT HAVE A ROLE - ORG APP NOT APPROVED
    // AND DOES NOT HAVE A MESSAGE - THEY HAVE TO START OR THEY HAVE AN APPLICATION IN PROGRESS
    setUserUnAuthorized(
      user._session?.role == null && user._session?.message == null
    );
    //IS USER HAS A ROLE OF GUARDIAN - THEY ARE A PARENT
    //AND THEY HAVE A STATUS OF 'not_signed_up' - THEY HAVE NOT COMPLETED SIGN UP FLOW
    const signUpOptions = ["not_signed_up"];
    signUpOptions.push("alternate_signup");

    if (user._session?.role?.name && user._session.status) {
      setParentHasInviteInProgress(
        user._session.role.name === "guardian" &&
          signUpOptions.includes(user._session.status)
      );
    } else {
      setParentHasInviteInProgress(false);
    }
    setParentSignup(location.pathname.includes("/signup/parents"));

    const role = user?.getRole();
    if (role && updateRoutes) {
      updateRoutes(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserReady]);

  useEffect(() => {
    if (user._session?.role?.name === "guardian" || user.isImpersonating()) {
      setIsAuthorized(true);
      setIsLoading(false);
    }
  }, [user, user._session?.role]);
  const [splitsReady, setSplitsReady] = useState(false);
  useEffect(() => {
    splits.isReady?.then(() => setSplitsReady(true));
  }, [setSplitsReady, splits]);

  return (
    <div className="absolute w-full h-full">
      <UserContext.Provider value={user}>
        <ProgramContext.Provider value={program}>
          <SplitIOContext.Provider value={splits}>
            {splitsReady && (
              <GroupContext.Provider value={group}>
                <SeasonContext.Provider value={season}>
                  <ToastContext.Provider value={toast}>
                    <DisplayContext.Provider value={display}>
                      {parentSignup ? (
                        <DisplaySignupFlow setIsLoading={setIsLoading} />
                      ) : parentHasInviteInProgress ? (
                        <DisplayInviteFlow setIsLoading={setIsLoading} />
                      ) : userHasApplicationInProgress ? (
                        <ApplicationForm />
                      ) : (
                        <>
                          {display.isDepositCheckOpen ? (
                            <Deposit />
                          ) : (
                            <div className="App relative">
                              <Header />
                              <MainNavigation
                                isAuthorized={isAuthorized}
                                userHasExpiredInvite={userHasExpiredInvite}
                              ></MainNavigation>

                              <main
                                className="pt-11 left-0 right-0 lg:pb-[35px] md:ml-[90px] lg:ml-[285px] bg-gray-100"
                                style={{ minHeight: "90vh" }}
                              >
                                {isLoading && (
                                  <div className="w-full h-screen">
                                    <div className="flex justify-center items-center h-full my-auto">
                                      <div>
                                        <SnapLoader />
                                        {textEnabled && (
                                          <h1
                                            className="text-2xl mt-10"
                                            id="snap-right-path"
                                          >
                                            Thank you for your patience while we
                                            retrieve your information...
                                          </h1>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {isUserReady && (
                                  <>
                                    {user._session?.role &&
                                    user._session?.userId ? (
                                      <>
                                        {location.pathname.includes(
                                          "/signup/parents"
                                        ) ? (
                                          <FixedModal
                                            isOpen={isSignoutOpen}
                                            toggle={signoutToggle}
                                            title={"Already Signed In"}
                                          >
                                            <div className="modal-card">
                                              <p>
                                                Already Signed in please sign
                                                out to continue
                                              </p>
                                            </div>
                                          </FixedModal>
                                        ) : (
                                          userHasRole && (
                                            <>
                                              {userHasExpiredInvite ? (
                                                <ExpiredInvite
                                                  setExpiredInvite={
                                                    setUserHasExpiredInvite
                                                  }
                                                />
                                              ) : isAuthorized ? (
                                                !!apiStatus?.apiReady && (
                                                  <Outlet />
                                                )
                                              ) : (
                                                <Authorization
                                                  isAuthorized={isAuthorized}
                                                  setIsAuthorized={
                                                    setIsAuthorized
                                                  }
                                                  setIsLoading={setIsLoading}
                                                  user={{
                                                    id: user._session.userId,
                                                    name: user.getName(),
                                                  }}
                                                />
                                              )}
                                            </>
                                          )
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {userHasCompletedApplication && (
                                          <AppicationStatus
                                            status={user?._session?.status}
                                            message={user?._session?.message!}
                                            setIsLoading={setIsLoading}
                                            applicationURL={user._session?.url}
                                          />
                                        )}

                                        {userUnAuthorized && (
                                          <UnAuthorized
                                            setIsLoading={setIsLoading}
                                          />
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                                <UnsavedChanges
                                  savedChangesOpen={isOpen}
                                  savedChangesToggle={toggle}
                                  path={pathToNav}
                                />
                              </main>
                              <Footer className="left-0 right-0 pb-24 px-1 lg:pb-[35px] md:pb-10 md:ml-[90px] lg:ml-[290px] bg-gray-100" />
                            </div>
                          )}
                        </>
                      )}
                    </DisplayContext.Provider>
                  </ToastContext.Provider>
                </SeasonContext.Provider>
              </GroupContext.Provider>
            )}
          </SplitIOContext.Provider>
        </ProgramContext.Provider>
      </UserContext.Provider>
      {/* {!parentSignup &&
        !parentHasInviteInProgress &&
        !userHasApplicationInProgress && (
          <HubspotWidget />
        )} */}
    </div>
  );
}

export default App;
