import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney, ParseMoney } from "helpers/format-money";
import { MapAndCalcSum } from "helpers/map-and-reduce";
import { useEffect, useState } from "react";
import { SnapInput, SnapTable } from "suit";
import { InvoiceReconciliationForm, TransactionAmountDistribution } from ".";

type TableProps = {
  selectedDetails: InvoiceReconciliationForm;
  setSelectedDetails: React.Dispatch<
    React.SetStateAction<InvoiceReconciliationForm>
  >;
  className?: string;
};

function Table({ selectedDetails, setSelectedDetails, className }: TableProps) {
  const [initialAmounts] = useState<TransactionAmountDistribution[]>([
    ...(selectedDetails.distribution ?? []),
  ]);
  const [balance, setBalance] = useState<number>(0);
  const getValue = (id: string | undefined | null) => {
    if (id == null) return 0;
    return initialAmounts.find((amt) => amt.invoice.id === id)?.amount ?? 0;
  };
  useEffect(() => {
    setBalance(
      (selectedDetails.transaction?.amount ?? 0) -
        (selectedDetails.transaction?.totalApplied ?? 0) -
        MapAndCalcSum(selectedDetails.distribution ?? [], "amount")
    );
  }, [initialAmounts, selectedDetails]);
  return (
    <SnapTable>
      <table className={`ui celled ${className}`}>
        <thead>
          <tr>
            <th className="w-[30%]">Invoice Description</th>
            <th>Due Date</th>
            <th className="flex justify-end">Amount Due</th>
            <th className="action-cell">Amount to Apply</th>
          </tr>
        </thead>
        <tbody>
          {selectedDetails.distribution?.map((amtInvoice) => {
            return (
              amtInvoice && (
                <tr key={`distrib-table-${amtInvoice.invoice.id}`}>
                  <td>
                    <p>{amtInvoice.invoice.description}</p>
                  </td>
                  <td>
                    <p>
                      {FormatDate(
                        new Date(amtInvoice.invoice.dueDate ?? ""),
                        DateFormatSupported.NumbersLight
                      )}
                    </p>
                  </td>
                  <td className="flex items-center justify-end">
                    <p>{FormatMoney(amtInvoice.invoice.balanceDue ?? 0)}</p>
                  </td>
                  <td>
                    <SnapInput
                      _id={""}
                      _type="number"
                      _prepend="$"
                      value={`${(
                        getValue(amtInvoice.invoice.id ?? undefined) / 100
                      ).toFixed(2)}`}
                      onSnap-input-change={(e) => {
                        const distribution = selectedDetails.distribution?.find(
                          (target) =>
                            target.invoice.id === amtInvoice.invoice.id
                        );
                        if (distribution) {
                          distribution.amount = ParseMoney(
                            e.detail.target.value
                          );
                          setSelectedDetails({ ...selectedDetails });
                        }
                      }}
                    />
                  </td>
                </tr>
              )
            );
          })}
          {selectedDetails.distribution && (
            <tr>
              <td></td>
              <td></td>
              <td className="pl-10">Balance Remaining</td>
              <td
                className={`flex justify-end items-center ${
                  balance !== 0 ? "text-red-500" : ""
                }`}
              >
                {FormatMoney(balance)}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </SnapTable>
  );
}

export default Table;
