import { DocumentNode, useLazyQuery } from "@apollo/client";
import GroupContext from "context/group-context";
import { ExportFile, SpendGroup } from "graphql/generated";
import { EMPTY, EXPORT_BUDGET } from "graphql/queries/budgets";
import { GET_ALL_BUDGETS } from "graphql/queries/dynamic-queries";
import { downloadFile } from "helpers/export-csv";
import getGroupSubtitle from "helpers/group-subtitle";
import { maybeExtract } from "helpers/maybe-helper";
import { BudgetSummaryGroupByType } from "helpers/summary-related";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SnapIcon } from "suit";
import { AllBudgetSummary } from "types/budget-summary";
import Cards from "./cards";
import Table from "./table";

function AllBudgets() {
  const Group = useContext(GroupContext);
  const navigate = useNavigate();
  const [
    exportAllBudget,
    { data: exportBudgetData, loading: exportBudgetLoading },
  ] = useLazyQuery(EXPORT_BUDGET);

  const [groupsLoaded, setGroupsLoaded] = useState(false);
  const [BUDGET_QUERY, SET_BUDGET_QUERY] = useState<DocumentNode>(EMPTY);
  const [getAllBudgetSummaries, { data: budgetData, loading: budgetLoading }] =
    useLazyQuery(BUDGET_QUERY);
  const [budgetSummary, setBudgetSummary] = useState<AllBudgetSummary[]>([]);
  const [spendGroups, setSpendGroups] = useState<SpendGroup[]>([]);
  const [budgetExporting, setBudgetExporting] = useState(false);

  useEffect(() => {
    if (exportBudgetData && exportBudgetData.spendBudgetExport) {
      setBudgetExporting(true);
      exportToCsv(exportBudgetData.spendBudgetExport);
      setBudgetExporting(false);
    }
    // eslint-disable-next-line
  }, [exportBudgetData, exportBudgetLoading]);

  useEffect(() => {
    if (Group?.groups?.length) {
      const budgets = GET_ALL_BUDGETS(
        maybeExtract(Group.groups.map((group) => group))
      );
      SET_BUDGET_QUERY(budgets);
      setSpendGroups(Group.groups);
    }
  }, [Group?.groups]);

  useEffect(() => {
    getAllBudgetSummaries();
    // eslint-disable-next-line
  }, [BUDGET_QUERY]);

  useEffect(() => {
    if (!budgetLoading && budgetData) {
      let groups = Group?.groups?.map((group, idx) => {
        const budget = budgetData[`${group.id}`];
        //! TODO improve this to default empty array instead of extra if/else
        if (budget) {
          const incomeCategoriesWithBudgets = BudgetSummaryGroupByType(
            budgetData[`${group.id}`]?.summaryByCategory,
            "income"
          );
          const expenseCategoriesWithBudgets = BudgetSummaryGroupByType(
            budgetData[`${group.id}`].summaryByCategory,
            "expense"
          );
          let incomeBudgetTotal = incomeCategoriesWithBudgets
            .map((b) => b.budgetAmount)
            .reduce((p, n) => p + n, 0);
          let incomeReconciledTotal = incomeCategoriesWithBudgets
            .map((b) => b.budgetReconciled)
            .reduce((p, n) => p + n, 0);
          let expenseBudgetTotal = expenseCategoriesWithBudgets
            .map((b) => b.budgetAmount)
            .reduce((p, n) => p + n, 0);
          let expenseReconciledTotal = expenseCategoriesWithBudgets
            .map((b) => b.budgetReconciled)
            .reduce((p, n) => p + n, 0);

          return {
            groupName: group.name,
            seasonName: getGroupSubtitle(group),
            unRecCredit:
              budgetData[`${group.id}`]?.summaryUnreconciled?.credits?.total,
            unRecDebit:
              budgetData[`${group.id}`]?.summaryUnreconciled?.debits?.total,
            budgetedIncomeTotal: incomeBudgetTotal,
            budgetExpenseTotal: expenseBudgetTotal,
            reconciledIncomeTotal: incomeReconciledTotal,
            reconciledExpenseTotal: expenseReconciledTotal,
          };
        } else {
          return {
            groupName: group.name,
            seasonName: getGroupSubtitle(group),
            unRecCredit:
              budgetData[`${group.id}`]?.summaryUnreconciled?.credits?.total,
            unRecDebit:
              budgetData[`${group.id}`]?.summaryUnreconciled?.debits?.total,
            budgetedIncomeTotal: 0,
            budgetExpenseTotal: 0,
            reconciledIncomeTotal: 0,
            reconciledExpenseTotal: 0,
          };
        }
      });
      setBudgetSummary(groups ?? []);
      setGroupsLoaded(true);
    }
    // eslint-disable-next-line
  }, [budgetData, budgetLoading]);

  const exportToCsv = (contentToExport: ExportFile) => {
    downloadFile({
      content: contentToExport.content,
      fileName: contentToExport.fileName,
      fileType: "text/csv",
    });
  };

  const handleGroupClick = (selectedGroup: SpendGroup) => {
    Group?.setAndStoreActiveGroup(selectedGroup);
    navigate("/groups/budget");
  };

  return (
    <div className="wrapper">
      <div className="card">
        <div className="flex justify-between mb-3">
          <p className="mr-auto lg:font-semibold font-medium text-lg">
            Budget Overview
          </p>
          <div
            className="flex text-blue-600 font-bold lg:text-base sm:text-sm cursor-pointer"
            onClick={() => {
              setBudgetExporting(true);
              exportAllBudget().then(() => {
                setBudgetExporting(false);
              });
            }}
          >
            <SnapIcon
              size="xs"
              className="pb-1"
              icon="download-solid"
            ></SnapIcon>
            <p className="ml-2">Export as CSV</p>
          </div>
        </div>
        {
          <>
            <Table
              groups={budgetSummary}
              className="hidden lg:table"
              handleGroupClick={handleGroupClick}
              spendGroups={spendGroups}
              groupsLoaded={groupsLoaded}
              budgetExporting={budgetExporting}
            />
            <Cards
              groups={budgetSummary}
              className="lg:hidden"
              handleGroupClick={handleGroupClick}
              spendGroups={spendGroups}
              groupsLoaded={groupsLoaded}
              budgetExporting={budgetExporting}
            />
          </>
        }
      </div>
    </div>
  );
}

export default AllBudgets;
