import DisplayContext from "context/display-context";
import GroupContext from "context/group-context";
import ProgramContext from "context/program-context";
import SeasonContext from "context/season-context";
import UserContext from "context/user-context";
import {
  Maybe,
  SpendGroup,
  SpendSeason,
  SpendSortOrderEnum,
  useSpendGroupsFilteredQuery,
} from "graphql/generated";
import useModal from "hooks/use-modal";
import { useSpendPagination } from "hooks/use-spend-pagination";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Divider from "shared-components/divider";
import FloatingActionBtn from "shared-components/floating-action-btn";
import AddGroup from "shared-components/modal/groups/add-group";
import ArchiveGroup from "shared-components/modal/groups/archive-group";
import CopyGroup from "shared-components/modal/groups/copy-group";
import { SearchInput } from "shared-components/search-input";
import ShowingResults from "shared-components/showing-results";
import Sort from "shared-components/sort";
import { SnapPagination } from "suit";
import { SpendPermissions } from "types/roles-permissions";
import { ITEMS_PER_PAGE } from "../../../constants";
import Cards from "./card";
import { dropDownMenuOptions } from "./external-data";
import GroupFilters from "./group-filters";
import Table from "./table";

type GroupsProps = {
  type: string;
};

function Groups({ type }: GroupsProps) {
  const navigate = useNavigate();
  const Program = useContext(ProgramContext);
  const Group = useContext(GroupContext);
  const Season = useContext(SeasonContext);
  const Display = useContext(DisplayContext);
  const canAddGroup = useContext(UserContext)?.checkSpendPermission(
    SpendPermissions.programGroupsUpdate
  );

  const { isOpen: addGroupOpen, toggle: addGroupToggle } = useModal();
  const { isOpen: copyGroupOpen, toggle: copyGroupToggle } = useModal();
  const { isOpen: archiveGroupOpen, toggle: archiveGroupToggle } = useModal();

  const [filterOptions, setFilterOptions] = useState([
    "On Hold",
    "Draft",
    "Published",
  ]);

  const [groupList, setGroupList] = useState<SpendGroup[]>([]);
  const [total, setTotal] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState<SpendGroup>();
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const {
    page,
    setPage,
    sort,
    sortValue: currentSort,
    toggleSort,
    handleSortValue,
    // setSort,
  } = useSpendPagination({
    field: "name",
    order: SpendSortOrderEnum.Asc,
  });
  const [searchText, setSearchText] = useState("");
  const [isArchived, setIsArchived] = useState(false);

  const { data, loading } = useSpendGroupsFilteredQuery({
    variables: {
      pagination: {
        limit: ITEMS_PER_PAGE,
        offset: page * ITEMS_PER_PAGE,
      },
      where: {
        archived: isArchived,
        nameIncludes:
          searchText && searchText.length > 2 ? searchText : undefined,
        paymentScheduleStatus:
          selectedFilters.length > 0 ? selectedFilters : undefined,
      },
      sort: sort,
    },
    fetchPolicy: "no-cache", // temporary solution
  });

  const handleModalData = (
    selectedOption: number,
    groupSelected?: SpendGroup
  ) => {
    switch (selectedOption) {
      case 0:
        addGroupToggle();
        break;
      case 1:
        copyGroupToggle();
        break;
      case 2:
        archiveGroupToggle();
        break;
    }
    setSelectedGroup(groupSelected);
  };

  useEffect(() => {
    if (!loading && data && data.spendGroupsFiltered?.groups) {
      if (page > 0 && data.spendGroupsFiltered.groups.length === 0) {
        setPage(page - 1);
      }
      setGroupList(data.spendGroupsFiltered.groups as SpendGroup[]);
      setTotal(data.spendGroupsFiltered.count || 0);
      if (Group?.activeGroup) {
        const updatedActiveGroup = data.spendGroupsFiltered.groups.find(
          (group) => group?.id === Group?.activeGroup?.id
        );
        if (updatedActiveGroup) {
          Group.setAndStoreActiveGroup({
            ...updatedActiveGroup,
            currentSeason: updatedActiveGroup.latestSeason || undefined,
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [data, loading]);

  useEffect(() => {
    if (type === "Active") {
      setIsArchived(false);
      Group?.setIsArchived(false);
      setFilterOptions(["On Hold", "Draft", "Published"]);
    } else {
      setIsArchived(true);
      Group?.setIsArchived(true);
      setFilterOptions(["Draft", "Published"]);
    }
    setPage(0);
    // eslint-disable-next-line
  }, [type]);

  const handleNavigate = (
    group: SpendGroup,
    latestSeason: Maybe<SpendSeason> | undefined
  ) => {
    Group?.setAndStoreActiveGroup({
      ...group,
      currentSeason: latestSeason || undefined,
    });
    navigate(
      `/groups/${group.sharedAccount ? "collections" : "group-banking"}`
    );
    Season?.handleSelectedSeason({ seasonsToSet: group.seasons });
  };

  return (
    <div className="wrapper">
      <div className="card">
        <SearchInput setSearchValue={setSearchText} />
        <GroupFilters
          setIsActionSheetOpen={setIsActionSheetOpen}
          selectedFilters={selectedFilters}
          isActionSheetOpen={isActionSheetOpen}
          fitlerOptions={filterOptions}
          setSelectedFilters={setSelectedFilters}
          setPage={setPage}
        />
        <Divider isVisibleOnMobile className="mt-6" />
        <ShowingResults
          totalNumOfResults={total}
          numOfResultsBeingDisplayed={
            total <= 10
              ? groupList.length
              : ITEMS_PER_PAGE * page + 10 >= total
              ? total
              : ITEMS_PER_PAGE * page + 10
          }
          startingNumOfResults={
            groupList.length === 0 ? 0 : ITEMS_PER_PAGE * page + 1
          }
        />

        {!Display?.isDesktop && (
          <Sort
            selectedSortOption={currentSort}
            options={dropDownMenuOptions}
            handleSort={(e) => handleSortValue(e.value)}
          />
        )}
        {Display?.isDesktop ? (
          <Table
            groupList={groupList}
            handleModalData={handleModalData}
            sort={sort}
            toggleSort={toggleSort}
            isArchived={isArchived}
            handleNavigate={handleNavigate}
            loading={loading}
          />
        ) : (
          <Cards
            groupList={groupList}
            handleModalData={handleModalData}
            isArchived={isArchived}
            handleNavigate={handleNavigate}
            loading={loading}
          />
        )}

        <SnapPagination
          currentPage={page}
          itemCount={total}
          pageSize={ITEMS_PER_PAGE}
          onSnap-pagination-page-changed={(e) => {
            setPage(e.detail);
          }}
        />
      </div>
      {canAddGroup && (
        <FloatingActionBtn
          onClick={() => handleModalData(0)}
          icon="plus-solid"
        />
      )}
      {addGroupOpen && (
        <AddGroup
          isAddGroupOpen={addGroupOpen}
          addGroupToggle={addGroupToggle}
          signupAgreement={Program?.settings?.signUpAgreement}
        />
      )}
      <CopyGroup
        copyGroupOpen={copyGroupOpen}
        copyGroupToggle={copyGroupToggle}
        selectedGroup={selectedGroup}
      />
      <ArchiveGroup
        archiveOpen={archiveGroupOpen}
        archiveToggle={archiveGroupToggle}
        selectedGroup={selectedGroup}
      />
    </div>
  );
}

export default Groups;
