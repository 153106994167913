import { useLazyQuery } from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import GroupContext from "context/group-context";
import ToastContext from "context/toast-context";
import {
  SpendCategory,
  SpendGroup,
  SpendGroupRoster,
  SpendInvoice,
  SpendSeason,
  useSpendTransactionReconcileV2Mutation,
} from "graphql/generated";
import { GET_CATEGORY_WITH_BUDGETS } from "graphql/queries/categories";
import { useContextStrict } from "helpers/context-strict";
import React, { useContext, useEffect, useState } from "react";
import DotProgress from "shared-components/dot-progress";
import CustomModal from "shared-components/modal";
import {
  ApplyingBankTransaction,
  ApplyingLegacyTransaction,
} from "types/team-banking";
import StepFive from "./step-five";
import StepFour from "./step-four";
import StepOne from "./step-one";
import StepThree from "./step-three";
import StepTwo from "./step-two";
import { emptyStringCheck } from "helpers/empty-string-check";

type ApplyToPlayerInvoiceProps = {
  applyToPlayerOpen: boolean;
  applyToPlayerToggle: () => void;
  transaction?: ApplyingBankTransaction | ApplyingLegacyTransaction;
  startAt?: number;
  team?: SpendGroup;
};
export type StepProps = {
  selectedDetails: InvoiceReconciliationForm;
  setSelectedDetails: React.Dispatch<
    React.SetStateAction<InvoiceReconciliationForm>
  >;
};
export type TransactionAmountDistribution = {
  invoice: SpendInvoice;
  amount: number;
};
export type InvoiceReconciliationForm = {
  team?: SpendGroup;
  season?: SpendSeason;
  participant?: SpendGroupRoster;
  transaction?: ApplyingBankTransaction;
  distribution?: TransactionAmountDistribution[];
};

function ApplyToPlayerInvoice({
  applyToPlayerOpen,
  applyToPlayerToggle,
  transaction,
  startAt,
  team,
}: ApplyToPlayerInvoiceProps) {
  const Group = useContext(GroupContext);
  const Toast = useContextStrict(ToastContext);
  const [
    reconcileTransactionV2,
    {
      data: reconcileV2Data,
      loading: reconcileV2Loading,
      error: reconcileV2Error,
    },
  ] = useSpendTransactionReconcileV2Mutation({
    refetchQueries: [
      "SpendTransactionsCompletedWhered",
      "SpendTransactionsLegacy",
    ],
    fetchPolicy: "network-only",
  });

  const [getCategories, { loading: loadingCategory, data: categoryData }] =
    useLazyQuery(GET_CATEGORY_WITH_BUDGETS);

  const [currentStep, setCurrentStep] = useState(0);
  const [selectedForm, setSelectedForm] = useState<InvoiceReconciliationForm>(
    {}
  );

  const [programGroups, setProgramGroups] = useState<SpendGroup[]>([]);
  const [categories, setCategories] = useState<SnapSelectMenuOption[]>([]);

  useEffect(() => {
    if (transaction) {
      const transactionAmount = getTransactionTotal();
      setSelectedForm({
        transaction: { ...transaction, amount: transactionAmount },
        team: team ?? undefined,
      });
      let groups =
        Group?.groups &&
        Group?.groups?.filter((group) => {
          return (
            group?.seasons &&
            group?.seasons.some((season) => {
              return season?.groupRoster && season.groupRoster.length > 0;
            })
          );
        });
      if (team) {
        groups = groups?.filter((group) => group.id === team.id);
        setSelectedForm({
          transaction: { ...transaction, amount: transactionAmount },
          team: groups?.at(0) ?? undefined,
        });
      }
      setProgramGroups(groups ?? []);
    }
    // eslint-disable-next-line
  }, [transaction, team, Group?.groups, getCategories]);

  useEffect(() => {
    if (!loadingCategory && categoryData && categoryData.spendCategories) {
      setCategories(
        categoryData.spendCategories.categories
          .filter((cat: SpendCategory) => cat.type === "income")
          .flatMap((cat: SpendCategory) => {
            return cat?.budgets?.flatMap((b) => {
              return {
                name: `${cat.name} | ${b?.description}`,
                value: `${b?.id}`,
                selected: false,
              };
            });
          })
      );
    }
  }, [categoryData, loadingCategory]);

  useEffect(() => {
    // if step changed, close the error if open
    if (Toast?.isToastOpen) {
      Toast?.toggleToast();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  useEffect(() => {
    if (
      !reconcileV2Loading &&
      reconcileV2Data &&
      reconcileV2Data.spendTransactionReconcileV2
    ) {
      closeModal();
    }
    if (!reconcileV2Loading && reconcileV2Error) {
      console.log(reconcileV2Error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reconcileV2Data, reconcileV2Loading, reconcileV2Error]);
  const getFormError = (): string | null => {
    const stepCheck = currentStep + (startAt ?? 0);
    if (stepCheck === 0 && !selectedForm.team) {
      return "Select a group to proceed.";
    }
    if (stepCheck === 1 && !selectedForm.season) {
      return "Select a season to proceed.";
    }
    if (stepCheck === 2 && !selectedForm.participant) {
      return "Select a participant to proceed.";
    }
    if (
      stepCheck === 3 &&
      selectedForm.distribution?.some(
        (distInv) =>
          distInv.invoice.balanceDue &&
          distInv.amount > distInv.invoice.balanceDue
      )
    ) {
      return "A distributed amount must be equal or lower than the balance due to proceed.";
    }
    if (
      stepCheck === 3 &&
      selectedForm.distribution?.every((distInv) => distInv.amount === 0)
    ) {
      return "At least one distribution must be more than 0 to proceed.";
    }
    const distributedAmount =
      selectedForm.distribution?.reduce((acc, inv) => acc + inv.amount, 0) ?? 0;
    const availableAmount =
      (selectedForm.transaction?.amount ?? 0) -
      (selectedForm.transaction?.totalApplied ?? 0);
    if (stepCheck === 3 && distributedAmount > availableAmount) {
      return "Amount distributed can not exceed available balance";
    }
    if (
      stepCheck === 4 &&
      selectedForm.distribution?.some(
        (distInv) =>
          distInv.amount > 0 && emptyStringCheck(distInv.invoice.budgetItemId)
      )
    ) {
      return "All invoices must be reconciled to a budget to proceed.";
    }
    return null;
  };
  const closeModal = () => {
    setSelectedForm({
      transaction: transaction ?? undefined,
      team: team ?? undefined,
    });
    setCurrentStep(0);
    applyToPlayerToggle();
  };
  const handleBudgetItemChange = (invoieId: string, budgetItemId: string) => {
    const distribution = selectedForm.distribution;
    const targetIdx =
      distribution?.findIndex((i) => i.invoice.id === invoieId) ?? 0;
    const targetDist = distribution?.at(targetIdx);
    if (targetDist) {
      const newInvoice = { ...targetDist.invoice };
      newInvoice.budgetItemId = budgetItemId;
      targetDist.invoice = newInvoice;
      distribution?.splice(targetIdx, 1, targetDist);
      setSelectedForm({ ...selectedForm, distribution: distribution });
    }
  };

  const getTransactionTotal = () => {
    return (transaction?.amount ?? 0) - Math.abs(transaction?.snapAmount ?? 0);
  };

  return (
    <CustomModal
      isOpen={applyToPlayerOpen}
      toggle={closeModal}
      customStyle="lg:w-[80%] lg:mt-[20px] lg:h-[70%]"
      title={"Apply Transaction to Participant Invoice"}
      btn1={{
        text: currentStep === 4 - (startAt ?? 0) ? "Apply" : "Next",
        btnStyle: "primary",
        onClick: () => {
          const error = getFormError();
          if (error == null) {
            const activeStep = currentStep + (startAt ?? 0);
            if (activeStep === 1) {
              getCategories({
                variables: {
                  groupId: selectedForm.team?.id,
                  seasonId: selectedForm.season?.id,
                },
              });
            }

            if (activeStep + 1 < 5) {
              if (activeStep === 0) {
                setSelectedForm({ ...selectedForm, season: undefined });
              }
              if (activeStep === 1) {
                setSelectedForm({ ...selectedForm, participant: undefined });
              }
              if (activeStep === 2) {
                setSelectedForm({ ...selectedForm, distribution: undefined });
              }
              setCurrentStep(currentStep + 1);
            } else {
              const transactionId = selectedForm.transaction?.id;
              const distributions = selectedForm.distribution?.filter(
                (dist) => dist.amount > 0
              );
              if (transactionId && distributions && distributions.length > 0) {
                const { amount, id } = selectedForm.transaction ?? {
                  amount: undefined,
                  id: undefined,
                };
                const invoices = [
                  ...distributions.map((dist) => ({
                    invoiceId: dist.invoice.id!,
                    amount: dist.amount || 0,
                    budgetItemId: dist.invoice.budgetItemId!,
                  })),
                ];
                if (amount && id) {
                  const variables = {
                    input: {
                      ledgerTransactionId: id,
                      ledgerTransactionAmount: amount,
                      invoices,
                    },
                  };
                  reconcileTransactionV2({ variables });
                }
              }
            }
          } else {
            Toast.setToastProps({ message: error, type: "danger" });
            Toast.toggleToast();
          }
        },
      }}
      btn2={{
        text: currentStep === 0 ? "Cancel" : "Back",
        btnStyle: "tertiary",
        onClick: () => {
          if (currentStep - 1 !== -1) {
            if (currentStep === 0) {
              getCategories({
                variables: {
                  groupId: selectedForm.team?.id,
                },
              });
            }
            setSelectedForm({ ...selectedForm });
            setCurrentStep(currentStep - 1);
          } else {
            closeModal();
          }
        },
      }}
    >
      <div className="modal-card">
        <DotProgress
          state={currentStep}
          markerCount={5 - (startAt ?? 0)}
          className="mt-2"
        />
        {
          {
            0: (
              <StepOne
                selectedDetails={selectedForm}
                setSelectedDetails={setSelectedForm}
                groups={programGroups}
              />
            ),
            1: (
              <StepTwo
                selectedDetails={selectedForm}
                setSelectedDetails={setSelectedForm}
              />
            ),
            2: (
              <StepThree
                selectedDetails={selectedForm}
                setSelectedDetails={setSelectedForm}
              />
            ),
            3: (
              <StepFour
                selectedDetails={selectedForm}
                setSelectedDetails={setSelectedForm}
              />
            ),
            4: (
              <StepFive
                selectedDetails={selectedForm}
                setSelectedDetails={setSelectedForm}
                budgetItems={categories}
                handleBudgetItemChange={handleBudgetItemChange}
              />
            ),
          }[currentStep + (startAt ?? 0)]
        }
      </div>
    </CustomModal>
  );
}

export default ApplyToPlayerInvoice;
