import GroupContext from "context/group-context";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import React, { useContext } from "react";
import Divider from "shared-components/divider";
import { SnapBadge, SnapCheckboxButton, SnapSelectMenu } from "suit";
import { SelectedInvoice } from "types/invoice";
import { calcTotalPlusFee } from "./make-payment-helper";

type InvoiceCardViewType = {
  list: SelectedInvoice[];
  isReadonly: boolean;
  handleCheckId: (e: SelectedInvoice | null, checked?: boolean) => void;
  handleOptStatus: (status: boolean, invoice: SelectedInvoice) => void;
  isAuthorizeFlow?: boolean;
};

export const InvoiceCardView = ({
  list,
  isReadonly = false,
  handleCheckId,
  handleOptStatus,
  isAuthorizeFlow,
}: InvoiceCardViewType) => {
  const activeGroup = useContext(GroupContext)?.activeGroup;
  const readOnly = isReadonly ? list.filter((x) => x.selected) : list;
  return (
    <React.Fragment>
      {readOnly &&
        readOnly.map((invoice, index) => {
          return (
            <div
              className="flex lg:hidden border p-4 rounded-lg mt-4"
              key={`invoice_description_${index}`}
            >
              <div className={`${(isReadonly || isAuthorizeFlow) && "hidden"}`}>
                <SnapCheckboxButton
                  disabled={!invoice.optedIn && !!invoice.isOptional}
                  className="mt-0.5"
                  checked={invoice.selected}
                  onClick={(e) =>
                    handleCheckId(
                      invoice,
                      (e.target as HTMLSnapCheckboxButtonElement).checked
                    )
                  }
                />
              </div>
              <div className="w-full">
                <p>{invoice.description}</p>
                <Divider isVisibleOnMobile />

                <div className="flex justify-between mt-3">
                  <p className="text-sm text-gray-500 font-semibold">
                    Due Date
                  </p>
                  <p>
                    {FormatDate(invoice.dueDate!, DateFormatSupported.Words)}
                  </p>
                </div>
                <div className="flex justify-between mt-3">
                  <p className="text-sm text-gray-500 font-semibold">
                    Pay by Bank
                  </p>
                  <p>
                    {FormatMoney(
                      calcTotalPlusFee(
                        invoice.balanceDue ?? 0,
                        activeGroup?.organizationFees?.achPercent ?? 0,
                        activeGroup?.organizationFees?.achBaseFee ?? 0
                      )
                    )}
                  </p>
                </div>
                <div className="flex justify-between mt-3">
                  <p className="text-sm text-gray-500 font-semibold">
                    Pay by Card*
                  </p>
                  <p>
                    {FormatMoney(
                      calcTotalPlusFee(
                        invoice.balanceDue ?? 0,
                        activeGroup?.organizationFees?.cardPercent ?? 0,
                        activeGroup?.organizationFees?.cardBaseFee ?? 0
                      )
                    )}
                  </p>
                </div>

                {invoice.isOptional && !isReadonly ? (
                  <div>
                    <Divider isVisibleOnMobile className="mt-2" />
                    <SnapSelectMenu
                      className="mt-2"
                      modalTitle="Select a Status"
                      selectMenuOptions={[
                        {
                          name: "Opt-In",
                          selected: invoice.optedIn ?? false,
                        },
                        {
                          name: "Opt-Out",
                          selected: !invoice.optedIn,
                        },
                      ]}
                      onSnap-select-menu-updated={(e) => {
                        let status = e.detail.find(
                          (option) => option.selected
                        )?.name;
                        handleOptStatus(status === "Opt-In", invoice);
                      }}
                    />
                  </div>
                ) : (
                  <div className="flex justify-between mt-3">
                    <p className="text-sm text-gray-500 font-semibold">
                      Optional
                    </p>

                    <SnapBadge className="flex justify-center">
                      {invoice.isOptional && invoice.optedIn
                        ? "Opt-In"
                        : "Required"}
                    </SnapBadge>
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </React.Fragment>
  );
};
