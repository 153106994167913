import { StripeEnvironment } from '@snap-mobile/payments-widget-utils';
import { PaymentConfiguration } from '../../V1/internal';
import { useState } from 'react';
import { ErrorType, PaymentsWidgetContextProps } from '../context/types';
import { V2 as api } from '@snap-mobile/payments-widget-client';

export const usePaymentsConfiguration = (
  paymentData: PaymentsWidgetContextProps['paymentData'],
  stripeEnvironment: StripeEnvironment,
  setError: (v: ErrorType) => void
) => {
  const [paymentConfiguration, setPaymentConfiguration] =
    useState<PaymentConfiguration>();
  const [loading, setLoading] = useState(false);

  const getPaymentConfiguration = async () => {
    try {
      setLoading(true);
      const res: PaymentConfiguration | null =
        await api.getPaymentConfiguration(paymentData.destination, {
          stripeEnvironment: stripeEnvironment,
        });
      if (res) {
        setPaymentConfiguration(res);
        setLoading(false);
      }
    } catch (e: any) {
      setError({ type: 'initiating', message: 'Connection Error, please try again later.' });
      console.error('Error getting configuration:', e?.message || e);
      setLoading(false);
    }
  };

  return {
    paymentConfiguration,
    getPaymentConfiguration,
    loading,
    setLoading,
  };
};
