import UserContext from "context/user-context";
import { SpendCategory } from "graphql/generated";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { MapAndCalcSum } from "helpers/map-and-reduce";
import { HandleSortingIcon } from "helpers/sorting-icon-change";
import { BudgetSummaryGroupByType } from "helpers/summary-related";
import { Fragment, useContext, useEffect, useState } from "react";
import Divider from "shared-components/divider";
import FloatingActionBtn from "shared-components/floating-action-btn";
import EditBudget from "shared-components/modal/edit-budget";
import { SnapButton, SnapDropDown, SnapIcon, SnapTable } from "suit";
import { selectedBudgetItem } from "types/budgets";
import { SpendPermissions } from "types/roles-permissions";
import DeleteBudget from "./modal/delete-budget";
import TableRowLabelValue from "./table-row-label-value";
import GroupContext from "context/group-context";

type BudgetItemsProps = {
  budgetItems: SpendCategory[] | undefined;
  type: "income" | "expense";
  budgetType: "program" | "group";
};

function BudgetItems({ budgetItems, type, budgetType }: BudgetItemsProps) {
  const group = useContext(GroupContext);
  const [selectedItem, setselectedItem] = useState<selectedBudgetItem>({
    budgetId: undefined,
    catId: undefined,
    category: "",
    budgetItem: "",
    date: "",
    amountApplied: "",
  });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [catTotals, setCatTotals] = useState<any[]>();
  const [overviewTotal, setOverviewTotal] = useState(0);
  const [items, setItems] = useState<selectedBudgetItem[]>([]);
  const [selectedTab, setSelectedTab] = useState("");
  const [icon, setIcon] = useState<
    "ascending-solid" | "descending-solid" | "selector-solid"
  >("selector-solid");

  useEffect(() => {
    if (budgetItems) {
      let overviewIncomeTotals = BudgetSummaryGroupByType(budgetItems!, type);
      setCatTotals(overviewIncomeTotals);
      let totalOverview = MapAndCalcSum(overviewIncomeTotals, "budgetAmount");
      setOverviewTotal(totalOverview);
      setItems(
        budgetItems?.flatMap((cat) => {
          return cat?.budgets?.flatMap((budget) => {
            return {
              budgetId: budget?.id || "",
              catId: cat.id || "",
              category: cat.name,
              budgetItem: budget?.description || "",
              date: FormatDate(
                budget?.targetDateAt || "",
                DateFormatSupported.Numbers
              ),
              amountApplied: budget?.targetAmount?.toString() || "",
            };
          });
        }) as selectedBudgetItem[]
      );
    }
  }, [budgetItems, type]);

  const canAddBudgetItem =
    useContext(UserContext)?.checkSpendPermission(
      budgetType === "program"
        ? SpendPermissions.programBudgetUpdate
        : SpendPermissions.groupBudgetUpdate
    ) ?? false;

  const handleModals = (selectedOption: string, item: selectedBudgetItem) => {
    setselectedItem({ ...item });
    if (selectedOption === "Edit") {
      setIsEditModalOpen(true);
    } else {
      setIsDeleteModalOpen(true);
    }
  };

  const handleSort = (category: string) => {
    let tempItems = [...items];
    let sort: "category" | "budgetItem" | "date" | "amountApplied" = "category";
    let direction = "";

    switch (category) {
      case "Category":
        sort = "category";
        break;
      case "Budget Item":
        sort = "budgetItem";
        break;
      case "Date":
        sort = "date";
        break;
      case "Amount Applied":
        sort = "amountApplied";
        break;
    }
    if (selectedTab !== category) {
      direction = "ascending";
    } else {
      switch (icon) {
        case "selector-solid":
          direction = "ascending";
          break;
        case "ascending-solid":
          direction = "descending";
          break;
        default:
          direction = "ascending";
          break;
      }
    }
    if (direction === "ascending") {
      tempItems.sort((a, b) => {
        return sort === "date"
          ? new Date(a[sort]) < new Date(b[sort])
            ? -1
            : new Date(a[sort]) > new Date(b[sort])
            ? 1
            : 0
          : sort === "amountApplied"
          ? Number(a[sort]) < Number(b[sort])
            ? -1
            : Number(a[sort]) > Number(b[sort])
            ? 1
            : 0
          : a[sort].toUpperCase() < b[sort].toUpperCase()
          ? -1
          : a[sort].toUpperCase() > b[sort].toUpperCase()
          ? 1
          : 0;
      });
    } else {
      tempItems.sort((a, b) => {
        return sort === "date"
          ? new Date(b[sort]) < new Date(a[sort])
            ? -1
            : new Date(b[sort]) > new Date(a[sort])
            ? 1
            : 0
          : sort === "amountApplied"
          ? Number(b[sort]) < Number(a[sort])
            ? -1
            : Number(b[sort]) > Number(a[sort])
            ? 1
            : 0
          : b[sort].toUpperCase() < a[sort].toUpperCase()
          ? -1
          : b[sort].toUpperCase() > a[sort].toUpperCase()
          ? 1
          : 0;
      });
    }
    setItems(tempItems);
  };

  return (
    <div className="my-4 lg:pb-0">
      <div className="lg:flex items-center lg:border border-b border-gray-200 lg:rounded-lg lg:p-4 pb-4">
        <div className="lg:w-1/4 flex flex-col gap-2">
          <p className="text-lg font-semibold text-gray-800 leading-7 lg:text-xl">
            Overview
          </p>
          <div className="border-gray-200 border-b  flex lg:hidden " />
          <div>
            <p className="text-gray-500 lg:font-medium">Total</p>
            <p
              className={`${
                group?.isArchived ? "text-gray-600" : "text-green-600"
              }  text-3xl font-semibold leading-9`}
            >
              {FormatMoney(overviewTotal)}
            </p>
          </div>
        </div>
        <div className="lg:flex flex-wrap lg:w-3/4 lg:pl-2 lg:border-l border-gray-300">
          {catTotals?.map((item, idx) => {
            return (
              <Fragment key={idx}>
                {idx === 5 && (
                  <div className="w-full border-t border-gray-300 hidden md:block"></div>
                )}
                <div
                  className={`flex lg:flex-wrap h-auto justify-between ${
                    catTotals.length > 5
                      ? "lg:w-1/5"
                      : `lg:w-1/${catTotals.length}`
                  } ${
                    idx % 5 ? "lg:border-l" : "border-none"
                  } border-gray-300 my-2 lg:pl-5`}
                >
                  <p className="md:w-full text-sm text-gray-500">{item.name}</p>
                  <p className="md:w-full font-semibold">
                    {FormatMoney(item.budgetAmount)}
                  </p>
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
      <div className="lg:flex justify-end my-4 hidden">
        <SnapButton
          hidden={!canAddBudgetItem || group?.isArchived}
          variant="primary"
          icon="plus-solid"
          onClick={() => {
            setselectedItem({
              budgetId: undefined,
              catId: undefined,
              category: "",
              budgetItem: "",
              date: "",
              amountApplied: "",
            });
            setIsEditModalOpen(true);
          }}
        >
          <span className="capitalize">Add Budget Item</span>
        </SnapButton>
      </div>
      {/* Mobile View */}
      {items.map((bItem, idx) => {
        return (
          <div
            className="lg:hidden mt-4 border border-gray-200 rounded-lg p-4"
            key={`${bItem?.budgetId} ${idx} Card`}
          >
            <div className="flex justify-between">
              <p className="text-base font-semibold">{bItem?.budgetItem}</p>
              {canAddBudgetItem && (
                <SnapDropDown
                  modalType="drawer"
                  minimal
                  options={[
                    { text: "Edit", value: "Edit", name: "Edit" },
                    { text: "Delete", value: "Delete", name: "Delete" },
                  ]}
                  onSnap-dropdown-item-selected={(e) =>
                    handleModals(e.detail.name, bItem)
                  }
                />
              )}
            </div>
            <Divider isVisibleOnMobile className="mt-2" />
            <p className="mt-2 text-sm text-gray-500">Category</p>
            <p className="text-sm font-semibold">{bItem.category}</p>
            <Divider isVisibleOnMobile className="my-2" />
            <div className="mt-5 w-full">
              <table className="w-full">
                <tbody>
                  <TableRowLabelValue label={"Date"} value={bItem.date} />
                  <TableRowLabelValue
                    label={"Amount"}
                    value={FormatMoney(Number(bItem?.amountApplied) || 0)}
                  />
                </tbody>
              </table>
            </div>
          </div>
        );
      })}
      {/*Web View  */}
      <SnapTable>
        <table className="ui celled lg:table hidden">
          <thead>
            <tr>
              <th></th>
              <th>
                Budget Item
                <SnapIcon
                  className="cursor-pointer"
                  icon={selectedTab === "Budget Item" ? icon : "selector-solid"}
                  size="xs"
                  onClick={() => {
                    handleSort("Budget Item");
                    HandleSortingIcon(
                      "Budget Item",
                      selectedTab,
                      setSelectedTab,
                      icon,
                      setIcon
                    );
                  }}
                />
              </th>
              <th>
                Category
                <SnapIcon
                  className="cursor-pointer"
                  icon={selectedTab === "Category" ? icon : "selector-solid"}
                  size="xs"
                  onClick={() => {
                    handleSort("Category");
                    HandleSortingIcon(
                      "Category",
                      selectedTab,
                      setSelectedTab,
                      icon,
                      setIcon
                    );
                  }}
                />
              </th>
              <th className="text-cell">
                Date
                <SnapIcon
                  className="cursor-pointer"
                  icon={selectedTab === "Date" ? icon : "selector-solid"}
                  size="xs"
                  onClick={() => {
                    handleSort("Date");
                    HandleSortingIcon(
                      "Date",
                      selectedTab,
                      setSelectedTab,
                      icon,
                      setIcon
                    );
                  }}
                />
              </th>
              <th className="icon-cell">
                Amount Applied
                <SnapIcon
                  className="cursor-pointer"
                  icon={
                    selectedTab === "Amount Applied" ? icon : "selector-solid"
                  }
                  size="xs"
                  onClick={() => {
                    handleSort("Amount Applied");
                    HandleSortingIcon(
                      "Amount Applied",
                      selectedTab,
                      setSelectedTab,
                      icon,
                      setIcon
                    );
                  }}
                />
              </th>
              {canAddBudgetItem && <th className="action-cell"></th>}
            </tr>
          </thead>
          <tbody>
            {items.map((bItem, idx) => {
              return (
                <tr key={`${bItem?.budgetId}  ${idx} Table`}>
                  <td></td>
                  <td>{bItem?.budgetItem}</td>
                  <td>{bItem.category}</td>
                  <td className="text-cell">{bItem.date}</td>
                  <td className="icon-cell">
                    {FormatMoney(Number(bItem.amountApplied) || 0)}
                  </td>
                  {canAddBudgetItem && !group?.isArchived && (
                    <td className="action-cell">
                      <SnapDropDown
                        minimal
                        options={[
                          { text: "Edit", value: "Edit", name: "Edit" },
                          {
                            text: "Delete",
                            value: "Delete",
                            name: "Delete",
                          },
                        ]}
                        onSnap-dropdown-item-selected={(e) =>
                          handleModals(e.detail.name, bItem)
                        }
                      />
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </SnapTable>
      {canAddBudgetItem && !group?.isArchived && (
        <FloatingActionBtn
          onClick={() => setIsEditModalOpen(true)}
          icon={"plus-solid"}
        />
      )}
      <EditBudget
        isOpen={isEditModalOpen}
        toggle={() => setIsEditModalOpen(false)}
        dataToEdit={selectedItem}
        categoryType={type}
        budgetType={budgetType}
      />
      <DeleteBudget
        isOpen={isDeleteModalOpen}
        toggle={() => setIsDeleteModalOpen(false)}
        dataToDelete={selectedItem}
        budgetItems={items}
      />
    </div>
  );
}

export default BudgetItems;
