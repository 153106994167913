import { V3 as api } from '@snap-mobile/payments-widget-client';
import { useCallback } from 'react';
import {
  CreatePaymentIntentData,
  PaymentMethod,
  User,
} from '@snap-mobile/payments-widget-utils';
import { useStripe } from '@stripe/react-stripe-js';
import { usePaymentsWidgetContext } from '../context';

export const usePaymentsConfirm = (paymentMethod: PaymentMethod) => {
  const {
    setError,
    setProcessing,
    paymentData,
    stripeEnvironment,
    paymentConfiguration,
    customer: _customer,
    invalidated,
    setupFutureUsage,
  } = usePaymentsWidgetContext();
  const stripe = useStripe();

  const {
    // payment info related
    description,
    externalPaymentId,
    idempotencyKey,
    metadata,
    snapAmount,
    // totalAmount,
    statementDescriptorSuffix,

    // payment method related
    permittedPaymentMethods,
    destination,
    finalDestination,

    // payment setup related
    automatic,
    returnUrl,
    refresh,
  } = paymentData;

  const confirmPayment = useCallback(
    async (customer?: User) => {
      if (invalidated) {
        console.warn('data props is invalid');
        return null;
      }
      if (!stripe) {
        console.error('stripe is not assigned');
        setError({ type: 'processing', message: 'Stripe is not assigned' });
        return null;
      }
      if (!paymentMethod.id) {
        console.error('paymentMethodId is not assigned');
        setError({
          type: 'processing',
          message: 'PaymentMethodId is not assigned',
        });

        return null;
      }
      const cus = customer || _customer;
      if (!cus) {
        console.error('customer is not assigned');
        setError({ type: 'processing', message: 'customer is not assigned' });
        return;
      }
      setProcessing(true);
      try {
        const data: CreatePaymentIntentData = {
          automaticPaymentMethods: { enabled: automatic ?? true },
          customer: cus!.customerId,
          description,
          destination: paymentConfiguration?.stripeAccountId ?? destination,
          externalPaymentId,
          finalDestination,
          idempotencyKey: idempotencyKey || '',
          paymentMethod: paymentMethod.id,
          metadata: metadata || {},
          permittedPaymentMethods,
          statementDescriptorSuffix: statementDescriptorSuffix,
          setupFutureUsage,
          snapAmount: snapAmount,
          totalAmount: paymentData.totalAmount,
        };

        const pi = await api.createPaymentIntent(data, { stripeEnvironment });
        if (pi.message) {
          setError({
            type: 'processing',
            message: pi.message ?? 'Error creating payment intent',
            data: {
              step: 'create_intent',
              code: pi.code,
              type: pi.type,
            },
          });
          setProcessing(false);
          return;
        }
        const redirect = refresh === false ? 'if_required' : undefined;
        const confirmData = {
          clientSecret: pi.client_secret,
          confirmParams: { return_url: returnUrl ?? window.location.href },
          redirect: redirect as any,
        };
        const { error, paymentIntent } = await stripe.confirmPayment(
          confirmData
        );
        if (error) {
          setError({
            type: 'processing',
            message: error.message ?? 'Error confirming payment.',
            data: {
              step: 'confirm_payment',
              code: error.code,
              type: error.type,
            },
          });
          return;
        }
        setProcessing(false);

        return paymentIntent;
      } catch (e: any) {
        setProcessing(false);
        setError({
          type: 'processing',
          message: e?.message || e,
          data: {
            code: e.code,
            type: e.type,
          },
        });
      }
    },
    [_customer, stripe, paymentData, paymentMethod.id]
  );

  return {
    confirmPayment,
  };
};
