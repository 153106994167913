import { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { V3 as api } from '@snap-mobile/payments-widget-client';
import {
  CreatePaymentIntentData,
  StripeEnvironment,
} from '@snap-mobile/payments-widget-utils';
import { ApplePayButton } from './buttons/ApplePayButton';
import { GooglePayButton } from './buttons/GooglePayButton';
import {
  CanMakePaymentResult,
  PaymentRequest,
  PaymentIntent,
  PaymentRequestPaymentMethodEvent,
} from '@stripe/stripe-js';
import { usePaymentsWidgetContext } from '../../context';
import { usePaymentsCustomer } from '../../hooks/usePaymentsCustomer';
import styles from './CustomExpressCheckout.module.scss';

export enum CustomExpressButtonTypes {
  Buy = 'Buy',
  Pay = 'Pay',
  Donate = 'Donate',
  None = 'none',
}
export type CustomExpressCheckoutProps = {
  stripeEnvironment?: StripeEnvironment;
  onApplePayButtonOnClick?: () => void;
  onGooglePayButtonOnClick?: () => void;
  onReady?: (res: CanMakePaymentResult | null) => void;
  onSuccess?: (data: PaymentIntent) => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  type?: CustomExpressButtonTypes;
};

export type CustomExpressCheckoutRef = {
  submit: () => void;
};

const PAYMENT_DONATION_LABEL = 'Donation';

/**
 * Props for `CustomExpressCheckout` Component
 *
 * @prop {StripeEnvironment} [stripeEnvironment=StripeEnvironment.RAISE] - Optional. Specifies the Stripe environment
 *                                                                        to be used. Defaults to 'RAISE'.
 * @prop {(data: PaymentIntent) => void | Promise<void>} onSuccess - Optional callback function invoked upon
 *                                                                   successful payment processing. Receives a
 *                                                                   `PaymentIntent` as its argument.
 * @prop {() => void | Promise<void>} onCancel - Optional callback function invoked upon cancellation of the payment process.
 * @prop {() => void} [onApplePayButtonOnClick] - Optional callback function triggered when the Apple Pay button is clicked.
 * @prop {() => void} [onGooglePayButtonOnClick] - Optional callback function triggered when the Google Pay button is clicked.
 * @prop {(res) => void} [onReady] - Optional callback function triggered when the payment request is ready. Useful for updating the styling.
 * @prop {ButtonTypes} [type=ButtonTypes.None] - Optional. Specifies the type of button to be rendered. Defaults to 'none'.
 *
 * This component provides a custom checkout experience for Stripe payments, specifically designed for
 * handling express checkout options like Apple Pay and Google Pay. It integrates Stripe's PaymentRequest API
 * for a streamlined payment process.
 *
 * Internally, it manages a `PaymentRequest` object to handle the payment process, checking if Apple Pay and Google Pay
 * are available and handling the payment submission. The component exposes a `submit` method via `useImperativeHandle`
 * for triggering the payment process externally.
 *
 * Usage:
 * <CustomExpressCheckout
 *   ref={ref}
 *   stripeEnvironment={StripeEnvironment.TEST}
 *   onApplePayButtonOnClick={() => console.log('Apple Pay Clicked') ref.current.submit()}
 *   onGooglePayButtonOnClick={() => console.log('Google Pay Clicked')}
 *   onReady={(res) => res? setExpressStyling('visible'):setExpressStyling('none') }
 * />
 */
export const CustomExpressCheckout = forwardRef(
  (
    {
      stripeEnvironment = StripeEnvironment.RAISE,
      onApplePayButtonOnClick,
      onGooglePayButtonOnClick,
      onReady,
      onSuccess,
      onCancel,
      type = CustomExpressButtonTypes.None,
    }: CustomExpressCheckoutProps,
    ref
  ) => {
    const { customer, paymentData, setError } = usePaymentsWidgetContext();
    const { createCustomer } = usePaymentsCustomer();
    const stripe = useStripe();
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
      null
    );
    const [canMakePayment, setCanMakePayment] =
      useState<CanMakePaymentResult | null>(null);

    const createPaymentRequestData = (label: string, amount: number) => {
      return {
        country: 'US',
        currency: 'usd',
        total: {
          label,
          amount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      };
    };

    const onSubmit = () => {
      setupPaymentRequestEvent(paymentData.totalAmount);
      paymentRequest?.show();
    };

    const handlePaymentMethodReceived = async (
      ev: PaymentRequestPaymentMethodEvent,
      total: number
    ) => {
      if (!stripe) return;
      const _paymentIntent = await createCustomerAndPaymentIntent(total, ev);
      if (!_paymentIntent?.client_secret) {
        console.error('Failed to create payment intent');
        return;
      }

      const { paymentIntent, error } = await stripe.confirmCardPayment(
        _paymentIntent.client_secret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
      );

      if (error) {
        ev.complete('fail');
        setError({
          type: 'processing',
          message:
            error.message ||
            'Error processing payment, please use a different payment method or try again.',
          data: {
            code: error.code,
            type: error.type,
          },
        });
        return;
      }
      onSuccess?.(paymentIntent);
      setError(null);
      ev.complete('success');
    };
    const createCustomerAndPaymentIntent = async (
      amount: number,
      ev: PaymentRequestPaymentMethodEvent
    ): Promise<PaymentIntent | null> => {
      const customerId =
        customer?.customerId || (await createCustomer())?.customerId;
      if (!customerId) {
        ev.complete('fail');
        return null;
      }

      const data: CreatePaymentIntentData = {
        customer: customerId,
        destination: paymentData.destination,
        snapAmount: paymentData.snapAmount,
        setupFutureUsage: 'on_session',
        totalAmount: amount,
        finalDestination: paymentData.finalDestination,
        externalPaymentId: paymentData.externalPaymentId,
        idempotencyKey: paymentData.idempotencyKey,
        description: paymentData.description,
        metadata: paymentData.metadata || {},
        automaticPaymentMethods: { enabled: true },
      };
      try {
        const paymentIntent = await api.createPaymentIntent(data, {
          stripeEnvironment,
        });
        if (paymentIntent.message) {
          setError({
            type: 'processing',
            message: paymentIntent.message ?? 'Error creating payment intent',
            data: {
              step: 'create_intent',
              code: paymentIntent.code,
              type: paymentIntent.type,
            },
          });
          ev.complete('fail');

          return null;
        }
        return paymentIntent;
      } catch (error: any) {
        const errorMessage =
          error?.message ||
          'Error processing payment, please use a different payment method or try again.';
        ev.complete('fail');
        setError({
          type: 'processing',
          message: errorMessage,
          data: {
            step: 'create_intent',
          },
        });
        return null;
      }
    };

    useImperativeHandle(ref, () => ({
      submit() {
        onSubmit();
      },
    }));

    useEffect(() => {
      if (stripe) {
        const paymentRequestData = createPaymentRequestData(
          PAYMENT_DONATION_LABEL,
          paymentData.totalAmount
        );
        const paymentRequest = stripe.paymentRequest(paymentRequestData);
        setPaymentRequest(paymentRequest);
        // Check the availability of the Payment Request API.
        paymentRequest
          .canMakePayment()
          .then((result: CanMakePaymentResult | null) => {
            if (result) {
              setCanMakePayment(result);
              onReady?.(result);
            }

            paymentRequest?.on('cancel', () => {
              paymentRequest.off('paymentmethod');
              onCancel?.();
            });
          });
      }

      return () => {
        if (paymentRequest) {
          paymentRequest.off('paymentmethod');
          paymentRequest.off('cancel');
        }
      };
    }, [stripe]);

    useEffect(() => {
      paymentRequest?.update({
        total: {
          label: paymentData.description ?? 'Snap Payments',
          amount: paymentData.totalAmount,
        },
      });
    }, [paymentData.totalAmount]);

    const setupPaymentRequestEvent = (amount: number) => {
      if (paymentRequest) {
        paymentRequest?.off('paymentmethod');
        paymentRequest.on(
          'paymentmethod',
          async (event: PaymentRequestPaymentMethodEvent) => {
            handlePaymentMethodReceived(event, amount);
          }
        );
      } else {
        console.warn("Payment Request haven't finished initialization");
      }
    };

    if (!canMakePayment) {
      return null;
    }

    return (
      <div className={`${styles.container} custom-express-payment-method`}>
        <ApplePayButton
          type={type}
          onClick={onApplePayButtonOnClick}
          disabled={canMakePayment?.applePay === false}
        />
        <GooglePayButton
          type={type}
          onClick={onGooglePayButtonOnClick}
          disabled={canMakePayment?.googlePay === false}
        />
      </div>
    );
  }
);
