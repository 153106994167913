import { gql } from "@apollo/client";

export const CREATE_BUDGET_ITEM = gql`
  mutation SpendBudgetCreate($input: SpendBudgetInput!) {
    spendBudgetCreate(input: $input) {
      id
    }
  }
`;

export const UPDATE_BUDGET_ITEM = gql`
  mutation SpendBudgetUpdate(
    $spendBudgetUpdateId: String!
    $input: SpendBudgetInput!
  ) {
    spendBudgetUpdate(id: $spendBudgetUpdateId, input: $input) {
      id
    }
  }
`;

export const DELETE_BUDGET_ITEM = gql`
  mutation SpendBudgetDelete($spendBudgetDeleteId: String!, $replace: String) {
  spendBudgetDelete(id: $spendBudgetDeleteId, replace: $replace) {
    id
  }
}
`;

export const UNRECONCILE_BUDGETS = gql`
  mutation SpendTransactionBudgetUnreconcile(
    $input: SpendTransactionBudgetUnreconcileInput!
  ) {
    spendTransactionBudgetUnreconcile(input: $input) {
      id
    }
  }
`;
